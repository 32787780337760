import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MbscEventcalendarOptions, MbscCalendarEvent, MbscResource, MbscCalendarColor, locale, MbscPageLoadingEvent } from '@mobiscroll/angular';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { orderBy } from '@progress/kendo-data-query';
import { DatePipe } from '@angular/common';
import { Guid } from 'guid-typescript';
import { IProdOrderCreateInput } from '@app/core/models/prod-order-create-input.model';
import { FileDownloadMode } from '@app/core/models/file-download-mode-enum';
import { KendoSchedulerViewTypeEnum } from '@app/core/models/kendo-scheduler-view-type.enum';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { NewProdOrderInputDTO } from '@app/core/models/new-prod-order-input-dto';
import { IProdOrderDTO } from '@app/core/models/prod-order';
import { IProdOrderCreateResult } from '@app/core/models/prod-order-create-form.model';
import { ProdOrderState } from '@app/core/models/prod-order-state.enum';
import { IScheduleLineDTO } from '@app/core/models/scheduleLineDTO.model';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { UnitConversionService } from '@app/core/services/custom-services/unit-conversion.service';
import { AoTBalanceService } from '@app/core/services/http-services/aot/aot-balance.service';
import { IEntity, IEntityRelation, EntityService, EntityRelationDirection } from '@app/core/services/http-services/model/entity.service';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { ProdOrderCalendarService } from '@app/modules/operative/services/prod-order-calendar.service';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DialogRef, DialogContentBase, DialogCloseResult } from '@progress/kendo-angular-dialog';

marker('Operative.CreateProdOrderInvalidQuantityErrMsg');
marker('Operative.CreateProdOrderInvalidDurationErrMsg');
marker('App.ProduceDirection');
marker('App.ConsumeDirection');
marker('Operative.NoMachineAgainstMachineGroupErrMsg');
marker('Operative.NoMachineFoundErrMsg');
marker('Operative.NoMachineGroupFoundErrMsg');
marker('Operative.CreateProdOrderNoActivityFoundErrMsg');
marker('Operative.CreateProdOrderErrMsg');
marker('Operative.ProdOrderTooltipMsg');
marker('Operative.ProdOrderSequeceCreated');
marker('Operative.CreateChainPOConsumeActivityNotFound');
marker('Operative.CreateChainError');

const createFormGroup = (e: IProdOrderCreateInput, start: Date) => new UntypedFormGroup({
  productIndex: new UntypedFormControl(e.productIndex, [Validators.required]),
  direction: new UntypedFormControl(e.direction ?? 0, [Validators.required]),
  activityIndex: new UntypedFormControl(null, [Validators.required]),
  machineId: new UntypedFormControl(null, [Validators.required]),
  entityRelationProductIndex: new UntypedFormControl(null, [Validators.required]),
  quantity: new UntypedFormControl(e.quantity ?? 0, [Validators.required, Validators.min(0.0001)]),
  baseQuantity: new UntypedFormControl(Math.abs(Number(e.quantity)) ?? 0, [Validators.required, Validators.min(0.0001)]),
  duration: new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]),
  unitCode: new UntypedFormControl(e.unitCode.length > 0 ? e.unitCode : 'M3', [Validators.required]),
  baseUnitCode: new UntypedFormControl(e.unitCode.length > 0 ? e.unitCode : 'M3', [Validators.required]),
  start: new UntypedFormControl(start, [Validators.required]),
  isEmptyStock: new UntypedFormControl(false),
  instruction: new UntypedFormControl('')
});

@Component({
  selector: 'app-prod-order-create-v2',
  templateUrl: './prod-order-create-v2.component.html',
  styleUrls: ['./prod-order-create-v2.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class ProdOrderCreateV2Component extends DialogContentBase  implements OnInit {
  slotDuration: number;
  form: UntypedFormGroup;
  formSequencer: UntypedFormGroup;
  prodModelActivity: IEntity[] = [];
  activityEntityRelations: IEntityRelation[];
  selectedeMaterial: IEntity;
  selectedeActivity: IEntity;
  checkChainingExists = false;
  productEntityRelDetails: IEntityRelation;

  // Dates
  public firstDate: Date = null;
  public lastDate: Date = null;

  // schedular
  machines: IMachineDTO[] = [];
  selectedDate = new Date();
  dateForecastCalculatedFor: Date = null;
  isLastEventGoesToNextDay = false;
  isLastEventEndsToCurrentDay = false;
  selectedDateShift = new Date();
  scheduleType: any = 'week';
  kendoSchedulerViewTypeEnum: KendoSchedulerViewTypeEnum = KendoSchedulerViewTypeEnum.Week;
  qty = 0;
  baseQty = 0;
  selectedMachineId = 0;
  defaultSelectedMachineId = 0;
  machinecolor:string;
  isCurrentWeekSelected = false;

  isEditQtyAndUnit:boolean = false;
  isSetBaseValues:boolean = false;
  eventcalendarOptions: MbscEventcalendarOptions = {
    dragToMove: true,
    dragTimeStep: 1,
    locale: locale['de'],
    onSelectedDateChange: (ev) => {
      this.myEvents = [];
      this.selectedDate = new Date(ev.date.toString());
      this.isCurrentWeekSelected = this.checkCurrentOrFutureWeek();

      if (this.kendoSchedulerViewTypeEnum === KendoSchedulerViewTypeEnum.Week) {
        this.setSelectedDateAsPerWeek();
        this.setFormStartAsPerTodaysDate();
      } else {
        this.setFormStart(new Date(ev.date.toString()));
      }
      this.getPOShiftStartTime().subscribe(x => {
        this.addReplaceNewEvent();
      });

      // Need to update forecast as well on data change
      this.getActivityMaterialForecastVolume();
    },
    onEventDragEnd: (ev, inst) => {
      if (this.kendoSchedulerViewTypeEnum === KendoSchedulerViewTypeEnum.Week) {
        this.selectedDate = new Date(ev.event.start.toString());
      }
      this.setFormStart(new Date(ev.event.start.toString()));
      this.removeNewEventFromCollection(this.myEvents);
      this.createNewEvent(new Date(ev.event.start.toString()), new Date(ev.event.end.toString()));
    }
  };

  // Observables
  public loadProdOrderPreviewSubs: Subscription;

  myEvents: MbscCalendarEvent[] = [];
  prodOrders: IProdOrderDTO[];
  selectedMachineGroupId: number;
  scheduleLines: IScheduleLineDTO[] = null;
  validScheduleLines: IScheduleLineDTO[] = null;
  shiftStartTime = new Date();
  myResources: MbscResource[] = [];
  colors: MbscCalendarColor[] = [];
  fileDownloadMode = FileDownloadMode;
  disabledEmptyStock = true;

  constructor(
    @Inject(DialogRef) public data: { input: IProdOrderCreateInput }
    , private dialogRef: DialogRef
    , private prodOrderService: ProdOrderService
    , private entityService: EntityService
    , private appNotificationService: AppNotificationService
    , private prodOrderCalendar: ProdOrderCalendarService
    , private aoTBalanceService: AoTBalanceService
    , public datepipe: DatePipe
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    let start = new Date(this.data.input.startDateTime) < new Date() ? new Date() : new Date(this.data.input.startDateTime);
    start = this.setDefaultStartDateHour(start);

    this.form = createFormGroup(this.data.input, start);
    this.selectedDate = new Date(start);
    this.isCurrentWeekSelected = this.checkCurrentOrFutureWeek();

    this.qty = CommonHelper.replaceCommawithDot(this.form.get('quantity').value);

    this.form.get('productIndex').valueChanges.subscribe(selectedProductIndex => {
      this.onMaterialChange(selectedProductIndex);
    });

    this.form.get('direction').valueChanges.subscribe(direction => {
      this.loadActivityDropDown();
    });

    this.form.get('activityIndex').valueChanges.subscribe(selectedActivityIndex => {
      this.onActivityChange(selectedActivityIndex);
    });

    this.form.get('machineId').valueChanges.subscribe(machineId => {
      this.onMachineChange(machineId);
    });

    this.form.get('entityRelationProductIndex').valueChanges.subscribe(prodIndex => {
      this.mainProductSelectchange(prodIndex);
    });

    this.form.get('duration').valueChanges.subscribe(duration => {
      this.onDurationChange(duration);
    });

    this.formSequencer = new UntypedFormGroup({
      productIndex: new UntypedFormControl(this.data.input.productIndex, [Validators.required]),
      start: new UntypedFormControl(this.data.input.startDateTime, [Validators.required]),
      qtyPcs: new UntypedFormControl(this.data.input.unitCode?.toUpperCase() === 'PCS' ? Math.abs(Number(this.data.input.quantity)) : null, [Validators.min(1), Validators.required, Validators.pattern('^[0-9]*$')])
    });
  }

  private onMaterialChange(productIndex: number) {
    this.entityService.get(productIndex)
      .subscribe(product => {
        this.selectedeMaterial = product;
        this.loadActivityDropDown();
      });
  }

  private onActivityChange(activityId: number) {
    if (activityId) {
      if (this.selectedeActivity?.index !== activityId) {
        this.entityService.get(activityId).subscribe(activity => {
          this.selectedeActivity = activity;

          this.activityEntityRelations = activity.entityRelations.filter(x => x.unitCode !== 'HOUR' && x.inOperative);

          if (this.activityEntityRelations !== undefined) {
            this.reCalcRelations();
            this.dateForecastCalculatedFor = null;
            this.getActivityMaterialForecastVolume();
          }

          if (!activity.entityGroup.machineGroupId) {
            this.appNotificationService.notifyErrorAppChanel('Operative.NoMachineGroupFoundErrMsg');
          }

          this.prodOrderCalendar.getResource(activity.entityGroup.machineGroupId).subscribe(re => {
            this.myResources = re.resources;

            this.machines = re.mashines;
            if (re.mashines.length > 0) {
              this.defaultSelectedMachineId = (this.machines.some(x => x.machineId === activity.machineId)) ? activity.machineId : this.machines[0].machineId;
              this.form.get('machineId').setValue(this.defaultSelectedMachineId);
              this.machinecolor = this.machines[0].colorCode;
            } else {
              this.appNotificationService.notifyErrorAppChanel('Operative.NoMachineFoundErrMsg');
            }

            this.selectedMachineGroupId = activity.entityGroup.machineGroupId;
          });

          this.checkChainingExists = activity.entityRelations.filter(x => x.isChaining === true).length > 0;

          if (activity.entityRelations[0]) {
            this.form.patchValue({
              entityRelationProductIndex: this.form.get('productIndex').value,
              unitCode: this.getselectedEntityRelation(this.form.get('productIndex').value).unitCode
            });
          }

          if (Number(CommonHelper.replaceCommawithDot(this.form.get('quantity').value)) > 0) {
            this.qty = CommonHelper.replaceCommawithDot(this.form.get('quantity').value);
            this.calcDuration();
          }
        });
      }
    }
  }

  private onMachineChange(machineId: number) {
    this.selectedMachineId = machineId;
    forkJoin([
      this.getEvents(this.firstDate),
      this.getPOShiftStartTime()
    ])
      .subscribe(ret => {
        this.selectedDateShift = new Date(this.form.get('start').value);
        this.addReplaceNewEvent();
      });
  }

  private onDurationChange(duration: number) {
    if (duration >= 0) {
      if (this.kendoSchedulerViewTypeEnum === KendoSchedulerViewTypeEnum.Week) {
        this.setSelectedDateAsPerWeek();
        this.setFormStartAsPerTodaysDate();
      }
      this.addReplaceNewEvent();
    }
  }

  onPageLoading(args: MbscPageLoadingEvent) {
    this.firstDate = args.firstDay;
    this.lastDate = args.lastDay;

    setTimeout(() => {
      this.getEvents(this.firstDate);
    });
  }

  private setSelectedDateAsPerWeek() {
    if (this.prodOrderCalendar.getMonday(this.selectedDate.setHours(0, 0, 0, 0)).getTime() === this.prodOrderCalendar.getMonday(new Date().setHours(0, 0, 0, 0)).getTime()) {
      this.selectedDate = new Date();
    } else {
      this.selectedDate = this.prodOrderCalendar.getMonday(this.selectedDate);
    }
  }

  getSlotDuration(slotTiming: number) {
    this.slotDuration = slotTiming;
  }

  getQtyIndifferent() {
    const qty = this.form.get('quantity').value;
    return Number(CommonHelper.replaceCommawithDot(qty.toString()));
  }

  getBaseQuantityIndifferent() {
    const qty = this.form.get('baseQuantity').value;
    return Number(CommonHelper.replaceCommawithDot(qty.toString()));
  }

  setBaseQtyAsPerDirection(direction: EntityRelationDirection) {
    return ((direction === EntityRelationDirection.CONSUMES ? -1 : 1) * (Number(CommonHelper.replaceCommawithDot(this.form.get('baseQuantity').value))));
  }

  private calcDuration() {
    this.prodOrderService.calculateDuration(
      this.form.get('activityIndex').value,
      this.form.get('entityRelationProductIndex').value,
      this.getQtyIndifferent())
      .subscribe(d => {
        this.form.get('duration').setValue(d);
      });
  }

  private getPOShiftStartTime(): Observable<Date> {
    let startDateTime = new Date(this.form.get('start').value);

    return this.prodOrderService.getPOShiftStartTime(this.form.get('machineId').value, startDateTime).pipe(map(shiftStart => {
      if (shiftStart !== null) {
        const ss = shiftStart.split(':');
        startDateTime.setHours(Number.parseInt(ss[0]), Number.parseInt(ss[1]));
      } else {
        startDateTime = this.setDefaultStartDateHour(startDateTime);
      }
      this.setFormStart(startDateTime);
      this.shiftStartTime = startDateTime;
      this.selectedDate = new Date(this.shiftStartTime);
      return startDateTime;
    }));
  }

  private setDefaultStartDateHour(startDate: Date): Date {
    return new Date(startDate.setHours(8, 0, 0, 0));
  }

  close() {
    this.dialogRef.close(<IProdOrderCreateResult>{ processProdOrder: false });
  }

  onUnlockRelations() {
    this.isEditQtyAndUnit = true;
    this.setRelationAsPerBaseQtyAndUnit();
  }

  private setRelationAsPerBaseQtyAndUnit() {
    this.activityEntityRelations.forEach(element => {
      if (element.productIndex === this.form.get('entityRelationProductIndex').value) {
        if (!element.isUnlockQtyRelation) {
          element.plannedQty = this.setBaseQtyAsPerDirection(element.direction);
          element.isUnlockQtyRelation = true;
        }
        if (!element.isUnlockUOMRelation) {
          element.unitCode = this.form.get('baseUnitCode').value;
          element.isUnlockUOMRelation = true;
        }
      }
    });
  }

  onCreate() {
    this.setRelationAsPerBaseQtyAndUnit();
    this.dialogRef.close(<IProdOrderCreateResult>{ processProdOrder: true, prodOrder: this.getProdOrderCreateResult(), relations: this.activityEntityRelations });
  }

  setBaseQtyOrPlannedQty(entityRelation: any): number {
    if (this.form.get('entityRelationProductIndex').value === entityRelation.productIndex) {
      return this.setBaseQtyAsPerDirection(entityRelation.direction);
    } else {
      return entityRelation.plannedQty ?? 0;
    }
  }

  onChangePlannedQty(updatedPlannedQty: any, entityRelation: any) {
    const activityEntityRelation = this.activityEntityRelations.find(x => x.productIndex === entityRelation.productIndex && x.activityIndex === entityRelation.activityIndex);
    activityEntityRelation.plannedQty = Number.parseFloat(CommonHelper.replaceCommawithDot((updatedPlannedQty.target.value).replace(/\s/g, '')));
    activityEntityRelation.isUnlockQtyRelation = true;
  }

  onUnitChangeForPOLine(updatedUnit: string, entityRelation: any) {
    const activityEntityRelation = this.activityEntityRelations.find(x => x.productIndex === entityRelation.productIndex && x.activityIndex === entityRelation.activityIndex);
    activityEntityRelation.unitCode = updatedUnit;
    activityEntityRelation.isUnlockUOMRelation = true;
  }

  onQuickCreate() {
    this.setRelationAsPerBaseQtyAndUnit();
    const prodOrder = this.getProdOrderCreateResult();
    this.prodOrderService.createProdOrder(prodOrder.machineId
      , prodOrder.activityIndex
      , prodOrder.productIndex
      , prodOrder.start
      , prodOrder.end
      , prodOrder.quantity
      , prodOrder.duration
      , prodOrder.isEmptyStock
      , prodOrder.instruction
      , prodOrder.siteId
    )
      .subscribe(newPO => {
        this.activityEntityRelations.forEach(element => {
          if (element.isUnlockQtyRelation) {
            newPO.prodOrderLines.find(x => x.materialIndex === element.productIndex).planedQty = element.plannedQty;
          }
          if (element.isUnlockUOMRelation) {
            newPO.prodOrderLines.find(x => x.materialIndex === element.productIndex).planedQtyUOM = element.unitCode;
          }
        });
        newPO.stateId = ProdOrderState.Preview;
        this.prodOrderService.saveProdOrder(newPO)
          .subscribe(
            prodOrderId => this.saveResponse(prodOrderId)
            , err => console.log(err)
          );
      });
  }

  onCreateChain() {
    const prodOrder = this.getProdOrderCreateResult();

    const dr = this.prodOrderService.openDialogForMultiStepPO(
      prodOrder.siteId
      , <NewProdOrderInputDTO> {
        materialIndex: prodOrder.productIndex,
        activityIndex: prodOrder.activityIndex,
        machineId: prodOrder.machineId,
        startDate: prodOrder.start,
        endDate: prodOrder.end,
        qty: prodOrder.quantity,
        duration: prodOrder.duration,
        instruction: prodOrder.instruction
      }
      , this.activityEntityRelations
    );

    dr.result.subscribe((result) => {

      // This will call when ProdOrder-CreateChain multi setup popup - close button clicked
      if (result instanceof DialogCloseResult) {
        dr.close();
      }
      else if (result) {
        this.close();
      }
    });
  }

  private getProdOrderCreateResult() {
    const prodOrder = this.form.value;
    const newProdOrder = this.myEvents.find(x => x.id === 0);
    if (newProdOrder !== undefined) {
      prodOrder.productIndex = prodOrder.entityRelationProductIndex; // This is becuase we have changed the main product US - 9844
      prodOrder.start = new Date(newProdOrder.start.toString());
      prodOrder.end = new Date(newProdOrder.start.toString());
      prodOrder.end.setMinutes(prodOrder.end.getMinutes() + this.form.get('duration').value);
      prodOrder.quantity = this.getQtyIndifferent();
      prodOrder.isEmptyStock = prodOrder.isEmptyStock !== undefined ? prodOrder.isEmptyStock : false;
      prodOrder.instruction = prodOrder.instruction !== undefined ? prodOrder.instruction : null;
      prodOrder.siteId = this.getSiteId();
      prodOrder.activityRelationLocked = !this.isEditQtyAndUnit;
    }
    return prodOrder;
  }

  private getSiteId() : Guid {
    return this.data.input.siteId;
  }

  private saveResponse(prodOrderId: number) {
    if (prodOrderId !== -1) {
      // this.spinner.hide('createProdOrderLoader');
      this.dialogRef.close(<IProdOrderCreateResult>{ processProdOrder: true, prodOrderId });
    } else {
      this.appNotificationService.notifyErrorAppChanel('Operative.CreateProdOrderErrMsg');
    }
  }

  private loadActivityDropDown() {
    if (this.selectedeMaterial.index && this.form.get('direction').value !== null) {
      this.entityService.getEntitiesThatProduce(this.selectedeMaterial.index, this.form.get('direction').value)
        .subscribe(d => {
          this.prodModelActivity = orderBy(d, [{ field: 'description', dir: 'asc' }]);
          if (d[0]) {
            this.form.get('activityIndex').setValue(d[0].index);
          } else {
            this.form.get('activityIndex').setValue(undefined);
            this.appNotificationService.notifyErrorAppChanel('Operative.CreateProdOrderNoActivityFoundErrMsg');
          }
        });
    }
  }

  private setFormStart(start: Date) {
    this.form.patchValue({
      start
    });
  }

  private setFormStartAsPerTodaysDate() {
    if (this.isLastEventGoesToNextDay) {
      this.setFormStart(new Date(this.selectedDate));
      this.isLastEventGoesToNextDay = false;
    } else {
      const shiftForToday = this.colors.find(x => new Date(x.start.toString()).toDateString() === this.selectedDate.toDateString());
      if (shiftForToday !== undefined) {
        this.selectedDate = new Date(shiftForToday.start.toString());
        this.setFormStart(new Date(this.selectedDate));
      } else {
        this.setFormStart(new Date(this.setDefaultStartDateHour(this.selectedDate)));
      }
    }
    this.isLastEventGoesToNextDay = false;
  }

  private removeNewEventFromCollection(events: MbscCalendarEvent[]): MbscCalendarEvent[] {
    const newProdOrderIndex = events.findIndex(x => x.id === 0);
    if (newProdOrderIndex !== -1) {
      events.splice(newProdOrderIndex, 1);
    }
    return events;
  }

  // For various cases refer attachments of US 8051
  private addReplaceNewEvent() {
    if (this.form.get('duration').value && Number(this.form.get('duration').value) > 0) {
      let start = new Date();
      let end = new Date();
      const duration = Number(this.form.get('duration').value);

      let events = this.removeNewEventFromCollection(this.myEvents);

      events = events.filter(x => x.resource === this.selectedMachineId);

      events.sort((a, b) => {
        return new Date(a.end.toString()).valueOf() - new Date(b.end.toString()).valueOf(); // sort events on basis of PO end date asc
      });

      // kendo schedular week view
      // considers week view and all the overlapping scenarios
      // scenario 11 to 17
      if (this.kendoSchedulerViewTypeEnum === KendoSchedulerViewTypeEnum.Week) {
        this.setSelectedDateAsPerWeek();
        this.setFormStartAsPerTodaysDate();

        let defaultDate = new Date(this.shiftStartTime);
        let isEventGoingNextWeek = false;
        let isFullWeekOccupied = false;

        events.forEach(event => {
          if (!isEventGoingNextWeek) {
            // check if there is any ongoing event on selected date
            if (new Date(event.start.toString()).getDate().valueOf() <= defaultDate.getDate().valueOf() && new Date(event.end.toString()).getDate().valueOf() >= defaultDate.getDate().valueOf()) {
              // if yes then fetch the end time of that event
              const lastEventEndDateTime = new Date(event.end.toString());

              // check whether the end time of ongoing event
              // it should be within the selected week
              // if its greater than the last date of selected week
              // means the event will be continued in the future week
              if (lastEventEndDateTime > this.lastDate) {
                isEventGoingNextWeek = true;
                isFullWeekOccupied = true;
              } else {
                // ongoing event is ending in the currently selected week
                defaultDate = new Date(lastEventEndDateTime);
              }
            }
          }
        });

        if (isFullWeekOccupied) {
          if (this.isCurrentWeekSelected) {
            // set todays date and time as selected date
            const date = new Date().setTime(this.shiftStartTime.getTime());
            this.selectedDate = new Date(date);
          } else {
            // set selected date as first day of the selected week
            this.setSelectedDateAsPerWeek();
            this.selectedDate.setTime(this.shiftStartTime.getTime());
          }
        } else {
          // if the selected week is not fully occupied
          this.selectedDate = new Date(defaultDate);
        }

        [start, end] = this.setNewEventStartEnd(new Date(this.selectedDate), 0, duration);
      } else {
        // kendo schedular day view
        // considers all the day view scenarios
        // scenarios 1 to 10
        const currentDateEvents = events.filter(x => (new Date(x.start.toString()).toDateString() === (this.selectedDate).toDateString()));

        if (currentDateEvents.length > 0) {
          currentDateEvents.forEach((d, index) => {
            const diffInMin = (index === 0)
              ? (((new Date(d.start.toString()).getTime() - this.selectedDate.getTime()) / 1000) / 60) // for Scenario 3
              : (((new Date(d.start.toString()).getTime() - new Date(currentDateEvents[index - 1].end.toString()).getTime()) / 1000) / 60); // for Scenario 4

            if (diffInMin >= duration) {
              [start, end] = (index === 0)
                ? (this.setNewEventStartEnd(new Date(this.selectedDate), 0, duration))
                : (this.setNewEventStartEnd(new Date(currentDateEvents[index - 1].end.toString()), 1, duration));
            } else {
              if (currentDateEvents.length === 1) {
                const newStartTime = new Date(d.end.toString());
                [start, end] = this.setNewEventStartEnd(new Date(newStartTime.setTime(newStartTime.getTime() + 1)), 0, duration);
              }
            }
          });
        } else {
          [start, end] = this.setNewEventStartEnd(new Date(this.selectedDate), 0, duration);
        }
      }

      this.createNewEvent(start, end).then((result) => {
        if (result) {
          this.highlightPOEvent();
        }
      }).catch((err) => {
        console.log('error: ', err.message);
      });
    }
  }

  private checkCurrentOrFutureWeek(): boolean {
    const newDateObj = new Date();
    const newDate = newDateObj.getDate();
    const newDateDay = newDateObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(newDateObj.setDate(newDate - newDateDay));
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 1);

    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return this.selectedDate.getDate() >= firstDayOfWeek.getDate() && this.selectedDate.getDate() <= lastDayOfWeek.getDate();
  }

  private setNewEventStartEnd(start: Date, startMinute: number, duration: number): [Date, Date] {
    const newEventStart = start;
    newEventStart.setMinutes(newEventStart.getMinutes() + startMinute);

    const newEventend = new Date(start);
    newEventend.setMinutes(start.getMinutes() + duration);

    return [newEventStart, newEventend];
  }

  private createNewEvent(start: Date, end: Date):Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.myEvents.filter(x => x.id === 0).length <= 0) {
        this.myEvents.push(<MbscCalendarEvent>{
          id: 0,
          start,
          startTimezone: null,
          end,
          endTimezone: null,
          isAllDay: false,
          title: 'NEW',
          description: '',
          machineId: this.defaultSelectedMachineId,
          resource: this.selectedMachineId,
          readOnly: false,
          cssClass: 'highlightPO'
        });
        this.myEvents = [...this.myEvents];
        setTimeout(resolve, 1000, true);
      }
      if (this.kendoSchedulerViewTypeEnum === KendoSchedulerViewTypeEnum.Week) {
        this.getActivityMaterialForecastVolume();
      }
    });
  }

  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  public reCalcRelations() {
    this.form.patchValue({
      entityRelations: this.prodOrderService.calculateEntityRelationQuantity(this.getBaseQuantityIndifferent(), this.activityEntityRelations, this.selectedeMaterial, this.form.get('baseUnitCode').value)
    });
  }

  getEvents(date: Date): Promise<boolean> {
    if (this.machines.length > 0) {
      return new Promise((resolve) => {
        this.loadProdOrderPreviewSubs = forkJoin([
          this.prodOrderCalendar.getEvents(date, this.kendoSchedulerViewTypeEnum, this.machines.map(m => m.machineId), this.lastDate),
          this.prodOrderCalendar.getDeviations(this.firstDate, this.lastDate, this.machines.map(m => m.machineGroupId)),
          this.prodOrderCalendar.getShedule(this.firstDate, this.lastDate, this.machines.map(m => m.machineGroupId))
        ]).subscribe(resp => {
          const res = resp[0].events.concat(resp[1]);
          // Prodorder Events and  colors
          if (this.myEvents.length > 0) {
            this.myEvents = this.myEvents.filter(x => x.id === 0);
            res.sort((a, b) => (a.start < b.start ? -1 : 1)).forEach(element => {
              this.myEvents.push(element);
            });
          } else {
            this.myEvents = res.sort((a, b) => (a.start < b.start ? -1 : 1));
          }

          this.prodOrders = resp[0].prodOrders;
          this.colors = resp[2];
          this.addReplaceNewEvent();
          resolve(true);
        });
      });
    }
  }

  public qtyChange(mainProductIndex: number, updatedUnit: string = null) {
    if (!this.isEditQtyAndUnit) {
      this.entityService.get(mainProductIndex).subscribe(entity => {
        const mainProduct = entity;
        this.baseQty = CommonHelper.replaceCommawithDot(this.form.get('baseQuantity').value);
        const convertedQty = this.prodOrderService.getQtyOnBaseQtyChange(Number(this.baseQty), this.activityEntityRelations, mainProduct, updatedUnit);
        this.form.patchValue({
          quantity: convertedQty
        });
        this.calcDuration();
        this.qty = CommonHelper.replaceCommawithDot(this.form.get('quantity').value);
        const entityrelations = this.prodOrderService.calculateEntityRelationQuantity(Number(this.qty), this.activityEntityRelations, mainProduct);
        this.form.patchValue({
          entityRelations: entityrelations
        });
      });
    }
  }

  public onUnitChangeForPO(updatedUnit: string, mainProductIndex: number) {
    this.qtyChange(mainProductIndex, updatedUnit);
  }

  private getselectedEntityRelation(productIndex: number): IEntityRelation {
    return this.activityEntityRelations.find(r => r.productIndex === productIndex);
  }

  mainProductSelectchange(productIndex: number) {
    // Get selected material details
    const selectedMaterialDetails = this.getselectedEntityRelation(productIndex);

    // toggel the use whole stock
    this.toggelIsEmptyStock(selectedMaterialDetails.direction);

    if (this.isSetBaseValues) {
      this.form.patchValue({
        baseUnitCode: selectedMaterialDetails.unitCode,
        baseQuantity: Math.abs(selectedMaterialDetails.plannedQty).toString()
      });
      this.qtyChange(productIndex);
    } else {
      this.form.patchValue({
        unitCode: selectedMaterialDetails.unitCode,
        quantity: Math.abs(selectedMaterialDetails.plannedQty).toString()
      });
    }

    this.calcDuration();
  }

  onMainLineChange() {
    this.isSetBaseValues = true;
  }

  getActivityMaterialForecastVolume() {
    if (this.dateForecastCalculatedFor == null || this.dateForecastCalculatedFor.toDateString() !== this.selectedDate.toDateString()) {
      this.aoTBalanceService.getActivityMaterialForecastVolume(this.form.get('activityIndex').value,
        this.selectedDate.toISOString(), this.getSiteId())
        .subscribe(p => {
          this.activityEntityRelations.forEach(a => {
            a.forecastVolume = p.find(r => r.entityIndex === a.productIndex)?.forecastVolume;
          });
        });
      this.dateForecastCalculatedFor = this.selectedDate;
    }
  }

  toggelIsEmptyStock(direction: EntityRelationDirection) {
    if (direction === EntityRelationDirection.CONSUMES) {
      this.disabledEmptyStock = false;
    } else {
      this.disabledEmptyStock = true;
      this.form.patchValue({
        isEmptyStock: false
      });
    }
  }

  highlightPOEvent() {
    const divElement = document.getElementsByClassName('highlightPO');
    if (divElement != null && divElement.length > 0) {
      for (let i = 0; i < divElement.length; i++) {
        const element = divElement[i] as HTMLElement;
        element.style.border = '6px solid ' + CommonHelper.invertColor(this.machinecolor);
      }
    }
  }
}
