/* eslint-disable indent */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { IHandelingUnit, HandelingUnitService } from '@app/core/services/http-services/stock/handeling-unit-report.service';
import { IHandelingUnitProperty, IHandelingUnitProp, QuantityReportService, IQuantityReport } from '@app/core/services/http-services/stock/quantity-report.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { HandelingUnitForm } from '@app/core/models/forms/operative/handlingUnit/handling-unit-form.model';

const createFormGroup = (hu: IHandelingUnit) => new FormGroup<HandelingUnitForm>({
  qty: new FormControl<number>(hu.qty, []),
  qtyUnitCode: new FormControl<number>(hu.qtyUnitCode, [])
});

@Component({
  selector: 'app-handeling-unit-form',
  templateUrl: './handeling-unit-form.component.html',
  styleUrls: ['./handeling-unit-form.component.css']
})
export class HandelingUnitFormComponent extends DialogContentBase implements OnInit, OnDestroy {
  listdata: IQuantityReport[] = [];
  griddatadetails: IHandelingUnitProperty[] = [];
  handelingunitprop: IHandelingUnitProp[] = [];
  viewquantity: Observable<GridDataResult>;

  state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  hu: IHandelingUnit;
  form: FormGroup<HandelingUnitForm> = createFormGroup(<IHandelingUnit><unknown>{ qty: 0, qtyUnitCode: 'ddd' });
  gridLoading = true;

  private destroy$ = new Subject<void>();
  constructor(
    @Inject(DialogRef)
    public data: { id: number },
    private readonly handelingunitreportservice: HandelingUnitService,
    private readonly quantityreportservice: QuantityReportService,
    private readonly dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.gridLoading = true;
    this.viewquantity = this.quantityreportservice;

    this.quantityreportservice.gethandelingunitprop()
    .pipe(takeUntil(this.destroy$))
    .subscribe(a => {
      this.handelingunitprop = a;
    });

    this.quantityreportservice.queryQuantity(this.state, this.data.id);

    this.handelingunitreportservice.get(this.data.id).subscribe(hu => {
      this.hu = hu;
      this.form = createFormGroup(hu);
    });
    this.gridLoading = false;
  }

 dataStateChange(state: DataStateChangeEvent): void {
    this.gridLoading = true;
    this.state = state;
    this.gridLoading = false;
  }

  getPropertyValue(dataItem: IQuantityReport, propcode: string) {
    const p = dataItem.handelingUnitPropValues.find(p => p.propCode === propcode);

    if (p) {
      return this.getStringValue(p);
    } else {
      return 'N/A';
    }
  };

  getStringValue(pv: IHandelingUnitProperty): string {
    if (pv.valueInt !== 0) {
      return pv?.valueInt?.toString();
    } else if (pv.valueDecimal !== 0) {
      return pv?.valueDecimal?.toString();
    } else {
      return pv?.valueString;
    }
  };

  public onSubmit() {

  }

  onClose() {
    this.dialogRef.close();
  }
}
