<kendo-dialog>
  <kendo-dialog-titlebar kendo-dialog-title>
    <div class="primaryColor section-header-operative" translate>Operative.ProdOrderReportTitle</div>
  </kendo-dialog-titlebar>
  <div class="container">
    <form [formGroup]="prodOrderReportForm" class="form-container">
      <div class="section-container">
        <section class="search-section">
          <kendo-formfield>
            <kendo-label translate>Operative.EventDate</kendo-label>
            <kendo-datepicker formControlName="eventDate"
              [value]="prodOrderReportForm.get('eventDate').value | siteDateFormat">
            </kendo-datepicker>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label translate>App.Partial</kendo-label>
            <kendo-switch formControlName="isPartial" color="primary">
            </kendo-switch>
          </kendo-formfield>
          <button kendoButton themeColor="primary" (click)="calculateReportedQty()">
            {{"Operative.CalculateReported" | translate }}
          </button>
          <div class="pt-20" kendoTooltip>
            <kendo-icon name="info" class="icon" title="{{ 'Operative.CalculateReportedHint' | translate }}"
              [themeColor]="themeColor"
              [size]="size"
            >
            </kendo-icon>
          </div>
        </section>
        <section class="pt-20">
          <table>
            <thead>
              <tr>
                <th translate>Operative.Line</th>
                <th translate>Operative.Main</th>
                <th translate>Operative.PlannedQty</th>
                <th translate>Operative.ReportedQty</th>
                <th class="width-10" translate> Operative.NewQty </th>
                <th translate>Operative.Package</th>
                <th class="width-16" translate> Operative.Material </th>
                <th translate>App.StockLocation</th>
                <th translate>App.Message</th>
                <th translate>App.Action</th>
              </tr>
            </thead>
            <tbody formArrayName="prodOrderReports">
              <tr *ngFor="let fg of getProdOrderReportsForm().controls let i = index" [formGroupName]="i">
                <td>{{ fg.get('lineNo').value }}</td>
                <td>{{ fg.get('isMain').value ? ('App.Yes' | translate) : ''}}</td>
                <td class="aligntext">
                  {{ fg.get('planedQty').value | number : '1.3'}}
                </td>
                <td class="aligntext">
                  <kendo-label formControlName="newQty">{{fg.get('newQty').value| number }}</kendo-label>
                </td>
                <td>
                  <kendo-textbox formControlName="reportedQty"
                    [ngModel]="fg?.get('reportedQty').value | ReplaceDotToCommaPipe">
                    <ng-template kendoTextBoxSuffixTemplate>
                      <kendo-textbox-separator></kendo-textbox-separator>
                      {{fg.get('reportedQtyUOM').value }}
                    </ng-template>
                  </kendo-textbox>
                </td>
                <td>
                  <app-select-package formControlName="handelingUnitId" [materialIndex]="fg.value.materialIndex"
                    [stockLocationId]="fg.value.stockLocationId" [isProdOrderReport]="true"
                    (handelingUnit)="packagechange(fg,$event)">
                  </app-select-package>
                </td>
                <td>
                  <app-select-entity formControlName="materialIndex" [entityTypeCode]="entityTypeCode"
                    (selectedEntity)="setSelectedMaterial($event)">
                  </app-select-entity>
                </td>
                <td>
                  <app-stock-location-selector formControlName="stockLocationId" [isEdge]="true"
                    (selectedStockLocationText)="setSelectedStockLocationText($event)">
                  </app-stock-location-selector>
                </td>
                <td>
                  <kendo-textarea formControlName="message" [cols]="30" [rows]="1">
                  </kendo-textarea>
                </td>
                <td class="btnwidth">
                  <div class="flex"></div>
                  <button kendoButton themeColor="primary" class="gap-10px" (click)="createcopy(fg,i)">
                    {{"App.Add" | translate }}
                  </button>
                  <button kendoButton themeColor="warning" (click)="removerow(i)">
                    {{"App.Remove" | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </form>
  </div>
  <kendo-dialog-actions>
    <div class="flex"></div>
    <button kendoButton type="button" class="btn closebtn" (click)="close()">
      {{"App.Close" | translate }}
    </button>
    <button kendoButton type="button" themeColor="primary" type="submit" (click)="save()" class="btn"
      [disabled]="!prodOrderReportForm.valid">
      {{"App.Submit" | translate}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
