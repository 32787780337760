import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormArray, FormControl, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { EntityGroupService, IEntityGroup, IEntityGroupProperty, IEntityGroupSite, Item } from '@app/core/services/custom-services/entity-group.service';
import { EntityPropertyService, IEntityProperty } from '@app/core/services/http-services/model/entity-property.service';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { IMachineGroupDTO, MachineGroupService } from '@app/core/services/http-services/operative/machine-group.service';
import { IUnit } from '@app/core/models/unit.model';
import { CreateEntityGroupPropertyFormGroup } from '@app/modules/entity-admin/entity-property-form/entity-property-form.component';
import { Guid } from '@app/core/models/Guid';
import { CreateEntityGroupForm } from '@app/core/models/forms/entity-admin/entity-group-form/create-entity-group-form.model';
import { EntityPropertyDataTypeEnum } from '@app/core/models/entity-property-data-type.enum';

export function customValidateArrayGroup(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const ide: Set<number> = new Set(formArray.value.map(v => v.propertyCode));

    return ide.size === formArray.controls.length ? null : { error: 'Entity property code already exist!!!' };
  };
};

const createFormGroup = (e: IEntityGroup, isNew: boolean) => new FormGroup<CreateEntityGroupForm>({
  index: new FormControl<number>(e.index, [Validators.required]),
  code: new FormControl<string>(e.code, [Validators.required]),
  description: new FormControl<string>(e.description, [Validators.required]),
  entityTypeCode: new FormControl<string>(e.entityTypeCode, [Validators.required]),
  resourceTypeCode: new FormControl<string>(e.resourceTypeCode),
  defaultBaseUnitCode: new FormControl<string>(e.defaultBaseUnitCode, [Validators.required]),
  sortOrderIndex: new FormControl<number>(e.index),
  userCode: new FormControl<string>(e.userCode),
  changeDate: new FormControl<Date>(e.changeDate),
  status: new FormControl<number>(e.status),
  commentId: new FormControl<number>(e.index),
  entityGroupProperties: new FormArray(e.entityGroupProperties.map<FormGroup>(k => CreateEntityGroupPropertyFormGroup(k, false, k?.property?.datatype)), customValidateArrayGroup),
  selectedProperty: new FormControl<string>(''),
  machineGroupId: new FormControl<number>(e.machineGroupId),
  stockLocationProduceId: new FormControl<number>(e.stockLocationProduceId),
  stockLocationConsumeId: new FormControl<number>(e.stockLocationConsumeId),
  isGlulamGroup: new FormControl<boolean>(e.isGlulamGroup),
  inTactical: new FormControl<boolean>(isNew ? true : e.inTactical),
  inOperative: new FormControl<boolean>(isNew ? true : e.inOperative),
  siteIds: new FormControl<Guid[]>(e.entityGroupSites.map(x => x.siteId))
});

@Component({
  selector: 'app-entity-group-form',
  templateUrl: './entity-group-form.component.html',
  styleUrls: ['./../entity-editor.component.css']
})

export class EntityGroupFormComponent extends DialogContentBase implements OnInit {
  public entityGroupForm: FormGroup;
  public entityGroup: IEntityGroup = <IEntityGroup>{ entityGroupProperties: [] };
  public props: IEntityProperty[];
  public units: IUnit[];
  public isNew: boolean;
  public machinegroups: IMachineGroupDTO[] = [];
  public entityTypeCode: Array<Item> = [{ text: 'ACTIVITY', value: 'ACTIVITY' }, { text: 'RESOURCE', value: 'RESOURCE' }, { text: 'NON_QUANT', value: 'NON_QUANT' }, { text: 'CHAIN_ACTIVITY', value: 'CHAIN_ACTIVITY' }];
  public resourceTypeCodes: Array<Item> = [{ text: '-', value: '' }, { text: 'CALTIME', value: 'CALTIME' }, { text: 'MTRL', value: 'MTRL' }];

  buildForm() {
    this.entityGroupForm = createFormGroup(this.entityGroup, this.isNew);
  }

  constructor(@Inject(DialogRef) public data: { entityGroup: IEntityGroup, isNew: boolean },
    public propService: EntityPropertyService, public entityGroupService: EntityGroupService, public unitService: UnitService, public machineGroupService: MachineGroupService,
    public formBuilder: FormBuilder,
    public dialogRef: DialogRef) {
    super(dialogRef);
    propService.get().subscribe(d => {
      this.props = d;
    });

    unitService.query({}).subscribe(u => {
      this.units = u;
    });
  }

  ngOnInit(): void {
    this.machineGroupService.query().subscribe(d => {
      this.machinegroups = d.data;
    });
    this.entityGroup = this.data.entityGroup;
    this.isNew = this.data.isNew;
    this.buildForm();
    if (this.entityGroup.entityTypeCode === 'ACTIVITY') {
      this.enableMachineGroup(false);
    } else {
      this.disableMachineGroup(false);
    }
    this.entityGroupForm.get('entityTypeCode').valueChanges.subscribe(v => this.entityTypeChange(v));
  }

  entityTypeChange(value) {
    if (value === 'ACTIVITY') {
      this.enableMachineGroup(true);
    } else {
      this.disableMachineGroup(true);
    }
  }

  enableMachineGroup(reset: boolean) {
    if (reset) {
      this.entityGroupForm.get('machineGroupId').reset();
    }
    this.entityGroupForm.get('machineGroupId').enable();
    this.entityGroupForm.get('machineGroupId').setValidators([Validators.required]);
    this.entityGroupForm.get('machineGroupId').updateValueAndValidity();
  }

  disableMachineGroup(reset: boolean) {
    if (reset) {
      this.entityGroupForm.get('machineGroupId').reset();
    }
    this.entityGroupForm.get('machineGroupId').clearValidators();
    this.entityGroupForm.get('machineGroupId').disable();
    this.entityGroupForm.get('machineGroupId').updateValueAndValidity();
  }

  get entityGroupProperties(): FormArray {
    return this.entityGroupForm.get('entityGroupProperties') as FormArray;
  }

  get entityGroupPropertiesActive(): FormArray {
    return this.entityGroupForm.get('entityGroupProperties') as FormArray;
  }

  getAsFormGroup(a: AbstractControl): FormGroup {
    return a as FormGroup;
  }

  gc(a: AbstractControl, n: string): FormControl {
    const k = a as FormGroup;
    return k.get(n) as FormControl;
  }

  removeProperty(index) {
    this.entityGroupProperties.at(index).get('isRemoved').patchValue(true);
    if (this.entityGroupProperties.at(index).get('isNew').value) {
      this.entityGroupProperties.removeAt(index);
    }
  }

  onSubmit() {
    console.log(this.entityGroupForm.value);
    this.entityGroup.index = this.entityGroupForm.value.index ?? -1;
    this.entityGroup.code = this.entityGroupForm.value.code;
    this.entityGroup.description = this.entityGroupForm.value.description;
    this.entityGroup.entityTypeCode = this.entityGroupForm.value.entityTypeCode;
    this.entityGroup.resourceTypeCode = this.entityGroupForm.value.resourceTypeCode;
    this.entityGroup.defaultBaseUnitCode = this.entityGroupForm.value.defaultBaseUnitCode;
    this.entityGroup.userCode = this.entityGroupForm.value.userCode;
    this.entityGroup.changeDate = this.entityGroupForm.value.changeDate;
    this.entityGroup.status = this.entityGroupForm.value.status ?? 0;
    this.entityGroup.commentId = this.entityGroupForm.value.commentId;
    this.entityGroup.machineGroupId = this.entityGroupForm.value.machineGroupId;
    this.entityGroup.stockLocationProduceId = this.entityGroupForm.value.stockLocationProduceId;
    this.entityGroup.stockLocationConsumeId = this.entityGroupForm.value.stockLocationConsumeId;
    this.entityGroup.isGlulamGroup = this.entityGroupForm.value.isGlulamGroup;
    this.entityGroup.inTactical = this.entityGroupForm.value.inTactical;
    this.entityGroup.inOperative = this.entityGroupForm.value.inOperative;
    this.entityGroup.entityGroupProperties = this.entityGroupProperties.value.map(c => {
      return <IEntityGroupProperty>{
        index: c.index,
        entityGroupIndex: this.entityGroup.index,
        propertyCode: c.propertyCode,
        isMandatory: c.isMandatory,
        defaultValueDouble: c.datatype === EntityPropertyDataTypeEnum.DATA_TYPE_DOUBLE && c.defaultValue != null ? parseFloat(c.defaultValue) : null,
        defaultValueInt: c.datatype === EntityPropertyDataTypeEnum.DATA_TYPE_INT && c.defaultValue != null ? parseInt(c.defaultValue) : null,
        defaultValueString: c.datatype === EntityPropertyDataTypeEnum.DATA_TYPE_STRING ? String(c.defaultValue) : null,
        isLength: c.isLenght ?? false,
        isWidth: c.isWidth ?? false,
        isQuality: c.isQuality ?? false,
        isSpecies: c.isSpecies ?? false,
        isThickness: c.isThickness ?? false,
        isNew: c.isNew,
        isChange: c.isChange,
        isRemoved: c.isRemoved
      };
    });
    this.entityGroup.entityGroupSites = this.entityGroupForm.value.siteIds.map(siteId => {
      return <IEntityGroupSite>{
        siteId: siteId,
        entityGroupIndex: this.entityGroup.index
      };
    });

    if (this.isNew) {
      this.entityGroupService.insert(this.entityGroup).subscribe(() => {
        this.dialogRef.close(this.entityGroup);
      });
    } else {
      this.entityGroupService.update(this.entityGroup).subscribe(m => {
        this.entityGroup.machineGroupName = m.machineGroupName;
        this.entityGroup.stockLocationConsumeName = m.stockLocationConsumeName;
        this.entityGroup.stockLocationProduceName = m.stockLocationProduceName;
        this.dialogRef.close(this.entityGroup);
      });
    }
  }

  cancleGroupUpdate() {
    this.dialogRef.close();
  }
}
