import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { IEntity } from '@app/core/services/http-services/model/entity.service';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DemandTypeEnum, getDemmandTypeValues } from '@app/core/models/aot-demand-type.enum';
import { PlanningTypeEnum, getPlanningTypeValues } from '@app/core/models/aot-planning-type.enum';
import { IDemand, DeamndService, IDemandPOSequenceDTO, IDemandInputForActivitySelection } from '@app/core/services/http-services/gluelam/demand.service';
import { Guid } from 'guid-typescript';
import { CreateDemandForm } from '@app/core/models/forms/aot/demand-form/create-demand-form.model';
import { DialogRef, DialogContentBase, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { IGlulamSequenceResult } from '@app/core/models/glulamsequenceDTO';
import { Subscription } from 'rxjs';

marker('Operative.AddDemandErrMsg');
marker('Operative.DemandAddedSuccessfully');
marker('Operative.DemandAndPOSeuqenceCreation');

const createFormGroup = (d: IDemand) => new FormGroup<CreateDemandForm>({
  siteId: new FormControl<Guid>(d.siteId ?? null, [Validators.required]),
  demandID: new FormControl<number>(d.demandID, [Validators.required]),
  entityGroupCode: new FormControl<string>(d.entityGroupCode, [Validators.required]),
  materialIndex: new FormControl<number|null>(d.materialIndex ?? 0),
  externalItemID: new FormControl<string>(d.externalItemID),
  qty: new FormControl<number>(d.qty ? Math.abs(d.qty) : null, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  qtyUnit: new FormControl<string>(d.qtyUnit ? d.qtyUnit : 'PCS'),
  length: new FormControl<number>(d.length, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  hight: new FormControl<number>(d.hight, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  width: new FormControl<number>(d.width, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  species: new FormControl<string>(d.species),
  isSpecialFormBeam: new FormControl<boolean>(false),
  specialFormName: new FormControl<string>({ value: '', disabled: true }),
  radius: new FormControl<string>({ value: '', disabled: true }, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  orderID: new FormControl<string>(d.orderID),
  orderRowID: new FormControl<string>(d.orderRowID),
  latestProductionDate: new FormControl<Date>(d.latestProductionDate ? new Date(d.latestProductionDate) : null, [Validators.required]),
  beamOrderRowID: new FormControl<number>(d.beamOrderRowID),
  materialCode: new FormControl<string>(d.materialCode),
  instruction: new FormControl<string>(''),
  demandType: new FormControl<number>(DemandTypeEnum.StockOrder),
  isActive: new FormControl<boolean>(false),
  planningType: new FormControl<number>(PlanningTypeEnum.Normal)
});

@Component({
  selector: 'app-demand-form',
  templateUrl: './demand-form.component.html',
  styleUrls: ['./demand-form.component.css']
})
export class DemandFormComponent extends DialogContentBase implements OnInit, OnDestroy  {
  disableSubmit: boolean = false;
  demandForm: FormGroup;
  demandTypes = getDemmandTypeValues();
  planningTypes = getPlanningTypeValues();

  private _demand: IDemand;
  private isDemandPOSequence: boolean = false;
  private subs: Subscription[] = [];

  constructor(
    @Inject(DialogRef) public data: { demand: IDemand },
    private readonly dialogRef: DialogRef,
    private readonly beamOrderRowService: DeamndService,
    private readonly notificationService: AppNotificationService) {
      super(dialogRef);
  }

  ngOnInit(): void {

    this._demand = this.data.demand;
    this.demandForm = createFormGroup(this._demand);

    this.subs.push(
      this.demandForm.get('demandType')
          .valueChanges
          .subscribe(value => {
            if (value) {
              switch (value) {
                case DemandTypeEnum.StockOrder:
                  this.demandForm.get('isActive').patchValue(false);
                  break;
                default:
                  this.demandForm.get('isActive').patchValue(true);
              }
            }
          }));

    this.subs.push(
      this.demandForm.get('isSpecialFormBeam')
      .valueChanges
      .subscribe(value => {
        if (value) {
          this.demandForm.get('specialFormName').enable();
          this.demandForm.get('radius').enable();
        } else {
          this.demandForm.get('specialFormName').reset({ value: '', disabled: true });
          this.demandForm.get('radius').reset({ value: '', disabled: true });
        }
      })
    );
  }

  setSelectedMaterial(material: IEntity) {
    if (material != null) {
      this.demandForm.patchValue({
        length: material.standardProperties.length,
        hight: material.standardProperties.thickness,
        width: material.standardProperties.width,
        species: material.standardProperties.species,
        entityGroupCode: material.entityGroup.index.toString(),
        externalItemID: material.code,
        materialCode: material.code
      });
    }
  }

  onSubmit() {
    const formValue = this.demandForm.value;

    const d: IDemand = <IDemand>{
      demandID: formValue.demandID ?? 0,
      entityGroupCode: formValue.entityGroupCode,
      materialIndex: formValue.materialIndex,
      externalItemID: formValue.externalItemID,
      qty: Number(CommonHelper.replaceCommawithDot(formValue.qty ?? -1)),
      qtyUnit: formValue.qtyUnit,
      length: formValue.length ?? 0,
      hight: formValue.hight ?? 0,
      width: formValue.width ?? 0,
      species: formValue.species,
      orderID: formValue.orderID,
      orderRowID: formValue.orderRowID,
      latestProductionDate: formValue.latestProductionDate,
      materialCode: formValue.materialCode,
      demandType: formValue.demandType,
      isActive: formValue.isActive,
      siteId: formValue.siteId,
      planningType: formValue.planningType,
      isSpecialFormBeam: formValue.isSpecialFormBeam,
      specialFormName: formValue.specialFormName,
      radius: formValue.radius
    };

    this.disableSubmit = true;

    const createDemandPOSequence = <IDemandPOSequenceDTO>{
      demand: d,
      isGenerateProdOrder: this.isDemandPOSequence,
      instruction: formValue.instruction,
      siteId: formValue.siteId
    };

    if (this.isDemandPOSequence) {
      const d : IDemandInputForActivitySelection = {
        beamOrderRowID: 0,
        orderId: formValue.orderID,
        orderRowId: formValue.orderRowID,
        materialIndex: formValue.materialIndex,
        currentPosition: 1,
        totalCount: 1
      };

      const dr = this.beamOrderRowService.openDialogForGlulamSeq(d);

      this.subs.push(
        dr.result
          .subscribe((data: IGlulamSequenceResult) => {
            if (data instanceof DialogCloseResult) {
              dr.close();
            } else if (data.success) {
              createDemandPOSequence.activitysSelected = data.lines;
              createDemandPOSequence.isGenerateProdOrder = true;
              this.insertDemand(createDemandPOSequence);
            } else {
              this.isDemandPOSequence = false;
              this.disableSubmit = false;
            }
          })
        );

    } else {
      this.insertDemand(createDemandPOSequence);
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onDemandPOSequence() {
    if (this.demandForm.get('qtyUnit').value && this.demandForm.get('qtyUnit').value !== 'PCS') {
      this.notificationService.notifyErrorAppChanel('Operative.AddAndGenerateSequenceErrorMsg');
      return;
    }
    this.isDemandPOSequence = true;
    this.onSubmit();
  }

  private insertDemand(createDemandPOSequence: IDemandPOSequenceDTO) {
    this.subs.push(
      this.beamOrderRowService
          .insertDemand(createDemandPOSequence)
          .subscribe({
            next: (beamOrderRowID) => {
              this.disableSubmit = false;
              if (this.isDemandPOSequence) {
                this.notificationService.notifySucsessAppChanel('Operative.DemandAndPOSeuqenceCreation');
              } else {
                this.notificationService.notifySucsessAppChanel('Operative.DemandAddedSuccessfully');
              }
              this.isDemandPOSequence = false;
              createDemandPOSequence.demand.beamOrderRowID = beamOrderRowID;
              this.dialogRef.close(createDemandPOSequence);
            },
            error: () => {
              this.disableSubmit = false;
              this.notificationService.notifyErrorAppChanel('Operative.AddDemandErrMsg');
            }
          })
        );
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => {
      x.unsubscribe();
    });
  }
}
