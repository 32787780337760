import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { Router } from '@angular/router';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IProdOrderStateDTO, IProdOrderDTO } from '@app/core/models/prod-order';
import { ProdOrderState } from '@app/core/models/prod-order-state.enum';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { CellCloseEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { IGridSetting } from '@app/core/models/gridSetting';
import { IUserSettingDTO } from '@app/core/models/userSettingDTO';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';
import { UserSettingkeyEnum } from '@app/core/models/user-setting-key.enum';
import { ProdOrderRemoveDialogService } from '@app/modules/operative/services/prod-order-edit.service';
import { shareReplay } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { IntlService } from '@progress/kendo-angular-intl';
import { IProdOrderBatchDTO } from '@app/core/models/prod-order-batch.model';
import { ProdOrderCustomService } from '@app/core/services/custom-services/prod-order-custom.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ProdOrderGridForm } from '@app/core/models/forms/orders/prod-order-grid-form.model';

marker('Operative.ProdOrderDeletedSuccessfullyMsg');
marker('Operative.ProdOrderLinkedToGlueset');
marker('Operative.ProdOrderLinkedToProdOrderSequence');
marker('Operative.ProdOrderDeleteErrorMsg');
marker('Operative.ProdOrdersDeleted');
marker('Operative.ProdOrderStateCanNotBeUpdate');
marker('Operative.ProdOrderDeleteNotAllowedDueToState');


@Component({
  selector: 'app-prod-order-grid',
  templateUrl: './prod-order-grid.component.html',
  styleUrls: ['./prod-order-grid.component.css']
})
export class ProdOrderGridComponent implements OnInit, AfterViewInit {
  loadProdOrderStates: boolean = false;
  formGroup: FormGroup<ProdOrderGridForm>;
  deniedRoles = [UserRoleEnum.Operator];
  selectedSequenceId: string = null;
  prodOrderData: GridDataResult;
  loading: boolean = false;
  state: State = {
    skip: 0,
    take: null,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };

  // State
  prodOrderId: number = undefined;
  cellClickedPOStateId: number = undefined;
  prodOrderStatesData: IProdOrderStateDTO[] = [];
  // Machine
  prodOrderMachineData: any = [];
  // Prod Order
  defaultSelectedStateIds: any[];
  defaultSelectedMachineIds: any[];
  prodOrderStateEnum = ProdOrderState;
  deleteProdOrderId: number = undefined;
  deleteProdOrderIds: number[] = null;
  activeDeleteProdOrder: IProdOrderDTO = null;
  prodOrderIds: number[] = [];
  contentHeight: number;
  changeStateBackToReleaseMsg:string;
  confirmStateUpdate = false;
  cellCloseEvent:CellCloseEvent;

  cellClickedPoStartdate: Date;

  private settingsId = UserSettingkeyEnum.grid_prodOrder_prodOrderList;
  private settings: IUserSettingDTO;

  @ViewChild('grdProdOrders')
  private grdProdOrders: GridComponent;

  @ViewChild('content') elementView: ElementRef;

  constructor(
    private readonly prodOrderService: ProdOrderService,
    private readonly appNotificationService: AppNotificationService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly machineService: MachineService,
    private readonly userSettingService: UserSettingService,
    private readonly prodOrderEditService: ProdOrderRemoveDialogService,
    private readonly intl: IntlService,
   private readonly prodOrderCustomService: ProdOrderCustomService
  ) {}

  ngOnInit(): void {
    const statesObr = this.getProdOrderStates();
    const machinesObr = this.getMachines();

    forkJoin([statesObr, machinesObr]).subscribe((x) => {
      this.getUserSettings();
    });
  }

  ngAfterViewInit() {
    this.calcTake();
  }

  getUserSettings(): void {
    this.userSettingService.getUserSetting(this.settingsId).subscribe((settings) => {
      if (settings) {
        this.convertGridSettingsToDTO(settings);
        // Convert all user columns from the database to grid columns.
        this.grdProdOrders.columns.forEach((column: any, index) => {
          let col = settings.columnSettings.find(x => x.columnName === column.field);
          if(!column.field)
          {
            col = settings.columnSettings.find(x => x.columnName === column.title);
          }
          column.width = col?.width ?? 100;
          column.orderIndex = col.orderIndex;
        });

        this.checkandApplyFilteringAndSorting();
      }
      this.loadProdOrder();
    });
  }

  onGridStateChange(): void {
    if (!this.settingsId) {
      return;
    }

    // We use setTimeout to allow the Kendo Grid to update the indexes and then we save the settings.
    setTimeout(() => {
      const settings = this.setGridSettings();
      this.convertGridSettingsToDTO(settings);
      this.saveOrUpdateUserSetting();
    });
  }

  loadProdOrder() {
    this.loading = true;
    if (!this.state.take) {
      return;
    }
    this.prodOrderIds = [];
    this.prodOrderService.query(this.state).subscribe((r) => {
      r.data.forEach((item) => {
        item.latestProductionDate = item.latestProductionDate ? new Date(item.latestProductionDate) : null;
        item.showDelete =
          item.stateId === this.prodOrderStateEnum.Preview || item.stateId === this.prodOrderStateEnum.Planned;
        item.isPOEndDateGreaterLPDate = this.checkExecutionGreaterProdDate(
          item.plannedExecutionEndDate,
          item.latestProductionDate
        );
        item.planedQty = item.mainLine?.planedQty;
        item.planedQtyUOM = item.mainLine?.planedQtyUOM;
      });

      this.prodOrderData = r;
      this.collapseProdOrderGridRow();
      this.loading = false;
    },
    (err) => this.handleResponseError(err));
  }

  checkExecutionGreaterProdDate(plannedExecutionEndDate: any, latestProductionDate: any) {
    if (
      plannedExecutionEndDate != null &&
      latestProductionDate != null &&
      plannedExecutionEndDate.split('T')[0] > latestProductionDate.toISOString().split('T')[0]
    ) {
      return true;
    }

    return false;
  }

  dataStateChange(state: State) {
    this.state = state;

    this.convertGridSettingsToDTO(this.setGridSettings());
    this.saveOrUpdateUserSetting();
    this.loadProdOrder();
  }

  detailExpand(e) {
    const prodOrder = e.dataItem as IProdOrderDTO;
    if (prodOrder.prodOrderLines.length === 0) {
      this.prodOrderService.getProdOrder(e.dataItem.prodOrderId).subscribe((po) => {
        prodOrder.prodOrderLines = po.prodOrderLines;
      });
    }
  }

  cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
    this.prodOrderId = dataItem.prodOrderId;
    this.cellClickedPOStateId = dataItem.stateId;
    this.cellClickedPoStartdate = new Date(dataItem.plannedExecutionStartDate);
  }

  cellCloseHandler(args: CellCloseEvent) {
    this.cellCloseEvent = args;
    this.updateProdorderState(args, false);
  }

  createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      stateId: dataItem.stateId,
      plannedExecutionStartDate: this.intl.parseDate(dataItem.plannedExecutionStartDate)
    });
  }

  getStateText(id: number) {
    return this.prodOrderStatesData?.find((i) => i.stateId === id)?.name ?? 'unknown';
  }

  deleteProdOrder(prodOrderIds: number[]) {
    this.loading = true;
    this.prodOrderEditService.showConfirmation(prodOrderIds).subscribe((r) => {
      this.loadProdOrder();
      this.prodOrderIds = [];
      this.loading = false;
    });
  }

  copyProdOrder(id: number) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/prod-order-copy/${id}`]));
    window.open(url, '_blank');
  }

  editProdOrder(id: number) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/prod-order/${id}`]));
    window.open(url, '_blank');
  }

  openBatchEditDialog() {
    if (this.prodOrderIds.length > 0) {
      const dr = this.prodOrderService
        .openBatchEditDialog(this.prodOrderIds);

      dr.result.subscribe((d) => {

        if (d instanceof DialogCloseResult) {
          dr.close();
        } else if (d) {
          this.loadProdOrder();
          this.prodOrderIds = [];
        }
      });
    }
  }

  confirmUpdate(isUpdate: boolean) {
    this.confirmStateUpdate = false;
    if (isUpdate) {
      this.updateProdorderState(this.cellCloseEvent, true);
    }
  }

  checkAccessForClosedPO(stateId:number) {
    return this.prodOrderCustomService.checkAccessForClosedPO(stateId);
  }

  private handleResponseError(error: any) {
    this.loading = false;
    console.error(error);
  }

  private collapseProdOrderGridRow() {
    this.prodOrderData.data.forEach((_, index) => {
      this.grdProdOrders.collapseRow(index);
    });
  }

  private getProdOrderStates(): Observable<IProdOrderStateDTO[]> {
    const obr = this.prodOrderService.getProdOrderStates().pipe(shareReplay(1));

    obr.subscribe((pos) => {
      this.prodOrderStatesData = pos;
    });

    return obr;
  }

  private getMachines(): Observable<GridDataResult> {
    const obr = this.machineService.getmachines().pipe(shareReplay(1));

    obr.subscribe((d: any) => {
      this.prodOrderMachineData = d.data;
    });

    return obr;
  }

  private updateProdorderState(args: CellCloseEvent, isStateUpdated:boolean) {
    const newStateId = args.formGroup.get('stateId').value;
    const newStartDate = new Date(args.formGroup.get('plannedExecutionStartDate').value);

    if (!isStateUpdated && this.cellClickedPOStateId > newStateId && newStateId === ProdOrderState.Released) {
      this.changeStateBackToReleaseMsg = this.prodOrderCustomService.getPOBackToReleaseMsg(this.cellClickedPOStateId);
      this.confirmStateUpdate = true;
    }
    if (this.cellClickedPOStateId !== newStateId && !this.confirmStateUpdate) {
      this.loading = true;
      this.prodOrderService
        .updateProdOrderOnStateChange(<IProdOrderDTO>{
          prodOrderId: this.prodOrderId,
          stateId: newStateId
        })
        .subscribe(
          (res) => this.updateResponse(res, args.dataItem, newStateId, null),
          (err) => this.handleResponseError(err)
        );
    }

    if (this.cellClickedPoStartdate.getTime() !== newStartDate.getTime()) {
      this.loading = true;
      const dto = <IProdOrderBatchDTO>{
        prodOrderIds: [args.dataItem.prodOrderId],
        plannedExecutionStartDate: newStartDate
      };

      this.prodOrderService.bulkUpdate(dto).subscribe(
        (res) => this.updateResponse(res > 0, args.dataItem, null, newStartDate),
        (err) => this.handleResponseError(err)
      );
    }
  }

  private calcTake() {
    this.contentHeight = this.elementView.nativeElement.offsetHeight;
    this.state.take = Math.ceil((this.contentHeight - 120) / 45);
  }

  private updateResponse(response: boolean, dataItem: IProdOrderDTO, newStateId: number, newStartDate: Date) {
    this.loading = false;
    if (response) {
      this.appNotificationService.notifySucsessAppChanel('Operative.UpdateSuccessMessage');
      this.loadProdOrder();
      if (dataItem !== null) {
        if (newStateId !== null) {
          dataItem.stateId = newStateId;
          dataItem.stateText = this.getStateText(newStateId);
        }
        if (newStartDate !== null) {
          dataItem.plannedExecutionStartDate = newStartDate;
          dataItem.startDate = newStartDate;
        }
      }
    } else {
      this.appNotificationService.notifyErrorAppChanel('Operative.UpdateProdOrderErrMsg');
    }
  }

  private saveOrUpdateUserSetting(): void {
    this.userSettingService.saveUserSetting(this.settings).subscribe((result) => {
    });
  }

  private convertGridSettingsToDTO(newSettings: any): void {
    this.settings = {
      key: this.settingsId,
      value: JSON.stringify(newSettings)
    };
  }

  private setGridSettings() {
    const settings: IGridSetting = {
      columnSettings: this.grdProdOrders.columns
        .toArray()
        .map((item) => <any>item)
        .map((item) => ({
          columnName: item.field ?? item.title,
          width: item.width ?? item.implicitWidth,
          orderIndex : item.orderIndex,
          sortBy: '',
          filterValue: '',
          filterOperator: '',
          group: ''
        })),
      filterSettings: []
    };

    if (this.state) {
      settings.columnSettings.forEach((setting) => {
        const sortData = this.state.sort.find((x) => x.field === setting.columnName);

        if (sortData) {
          setting.sortBy = sortData.dir;
        }

        let filterData: any;

        if (setting.columnName === 'stateText') {
          filterData = this.state.filter.filters.find((x: any) => x.field === 'stateId');
        } else if (setting.columnName === 'machineName') {
          filterData = this.state.filter.filters.find((x: any) => x.field === 'machineId');
        } else {
          filterData = this.state.filter.filters.find((x: any) => x.field === setting.columnName);
        }

        if (filterData) {
          setting.filterOperator = filterData.operator;
          setting.filterValue = filterData.value;
        }
      });
    }

    return settings;
  }

  private checkandApplyFilteringAndSorting(): void {
    const filterAndSortingSettings = JSON.parse(this.settings.value);

    if (filterAndSortingSettings) {
      const filters = [];

      filterAndSortingSettings.columnSettings.forEach((setting) => {
        if (setting.sortBy) {
          this.state.sort.push({
            dir: setting.sortBy,
            field: setting.columnName
          });
        }
        if (setting.filterValue) {
          if (setting.columnName === 'stateText') {
            setting.columnName = 'stateId';
            this.defaultSelectedStateIds = [];
            this.defaultSelectedStateIds = this.setDefaultSelectedIds(setting.filterValue);
          } else if (setting.columnName === 'machineName') {
            setting.columnName = 'machineId';
            this.defaultSelectedMachineIds = [];
            this.defaultSelectedMachineIds = this.setDefaultSelectedIds(setting.filterValue);
          }
          filters.push({
            field: setting.columnName,
            operator: setting.filterOperator,
            value:
              setting.columnName.toLowerCase().indexOf('date') !== -1
                ? new Date(setting.filterValue)
                : setting.filterValue
          });
        }
      });

      this.state.filter = {
        logic: 'and',
        filters
      };
    }
  }

  private setDefaultSelectedIds(value: any): number[] {
    const selectedIds: number[] = [];
    const ids = value.split(',');

    if (ids.length > 0) {
      ids.forEach((id: string) => {
        selectedIds.push(+id);
      });
    }
    return selectedIds;
  }
}
