import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISearchAoTBalanceCriteria } from '@app/core/models/searchAoTBalanceCriteria.model';
import { AoTResultDTO } from '@app/core/models/aotGridResultLineDTO.model';
import { IEntityForecastVolumeResponseDTO } from '@app/core/models/entity-forecast-volume.model';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class AoTBalanceService {
  constructor(private http: HttpClient) {}

  setSearchAoTBalanceHttpParam(sc: ISearchAoTBalanceCriteria) {
    let params = new HttpParams()
      .set('entityGroupIndex', sc.entitygroupIndex)
      .set('periodTypeId', sc.period.periodType)
      .set('periodCount', sc.period.periodCount)
      .set('groupingId', sc.group.toString())
      .set('unitCode', sc.unitCode)
      .set('date', sc.date)
      .set('parentGroupText', sc.parentGroupText)
      .set('siteId', sc.siteId?.toString());

    sc.transTypeMainId.forEach((element) => {
      params = params.append('transTypeMainId', element.toString());
    });

    sc.transTypeSecondaryId.forEach((element) => {
      params = params.append('transTypeSecondaryId', element.toString());
    });

    return params;
  }

  searchAoTBalance(sc: ISearchAoTBalanceCriteria): Observable<AoTResultDTO> {
    const params = this.setSearchAoTBalanceHttpParam(sc);
    return this.http.get<AoTResultDTO>(`${environment.apiUrl}AoTBalance/SearchAoTBalance`, { params });
  }

  searchChildAoTBalance(sc: ISearchAoTBalanceCriteria): Observable<AoTResultDTO> {
    const params = this.setSearchAoTBalanceHttpParam(sc);
    return this.http.get<AoTResultDTO>(`${environment.apiUrl}AoTBalance/SearchAoTBalance`, { params });
  }

  public getLowestBudgetPeriod(): Observable<string> {
    return this.http.get<string>(`${environment.apiUrl}AoTBalance/GetLowestBudgetPeriod`);
  }

  getActivityMaterialForecastVolume(
    activityIndex: number,
    date: string,
    siteId: Guid
  ): Observable<IEntityForecastVolumeResponseDTO[]> {
    const params = new HttpParams()
      .set('date', date)
      .set('activityIndex', activityIndex.toString())
      .set('siteId', siteId.toString());

    return this.http.get<IEntityForecastVolumeResponseDTO[]>(
      `${environment.apiUrl}AoTBalance/getActivityMaterialForecastVolume`,
      { params }
    );
  }
}
