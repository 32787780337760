import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State } from '@progress/kendo-data-query';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { IInstruction } from '@app/core/models/instruction.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AddEditInstructionDialogComponent } from '@app/modules/operative/add-edit-instruction-dialog/add-edit-instruction-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class InstructionService {
  public _instructions : IInstruction[] = null;

  constructor(private kendoGridService: KendoGridService,
    public dialogService: DialogService,
    private http: HttpClient) {
  }

  public query(): Observable<Array<IInstruction>> {
    return this.http.get<IInstruction[]>(`${environment.apiUrl}Instruction/getInstructions`)
      .pipe(tap(u => {
        this._instructions = u;
      }));
  }

  getInstructions(state: State) : Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    stateFilter.filter.forEach(element => {
      params = params.append(element.code, element.value);
    });

    return this.http
      .get<GridDataResult>(`${environment.apiUrl}Instruction`, { params });
  }

  insert(instruction: IInstruction): Observable<IInstruction> {
    return this.http.post<IInstruction>( `${environment.apiUrl}Instruction`, JSON.stringify(instruction), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  remove(data: IInstruction): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}Instruction/${data.instructionID}`);
  }

  openDialog(instruction: IInstruction, isNew:boolean): DialogRef {
    const dialogRef = this.dialogService.open({
      content: AddEditInstructionDialogComponent
    });

    const content = dialogRef.content.instance as AddEditInstructionDialogComponent;
    content.data = { instruction, isNew };

    return dialogRef;
  }
}
