import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { ObjectState } from '@app/core/models/object-state.enum';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { RamConfigurationService } from '@app/core/services/http-services/operative/ram-configuration.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CreateRamConfigurationForm } from '@app-models/forms/machines/create-ram-configuration-form.model';

marker('Machine.RamConfigurationSuccessMsg');
marker('Machine.RamConfigValidationErrorMsg');
marker('Operative.MachineMaxLengthErrorMessage');
marker('Operative.RamConfigurationOverlappingErrorMessage');

const createRamConfigurationForm = (p: IPressbedRamConfigurationDTO) => new FormGroup<CreateRamConfigurationForm>({
  width: new FormControl<number>(p.width),
  state: new FormControl<ObjectState>(p.state),
  index: new FormControl<number>(p.index),
  position: new FormControl<number>(p.position),
  machineId: new FormControl<number>(p.machineId),
  ramConfigurationId: new FormControl<number>(p.pressbedRamConfigurationId)
});

@Component({
  selector: 'app-ram-configuration-dialog',
  templateUrl: './ram-configuration-dialog.component.html',
  styleUrls: ['./ram-configuration-dialog.component.css']
})
export class RamConfigurationDialogComponent extends DialogContentBase implements OnInit {
  isNew = false;
  loading = false;
  formGroup: FormGroup;
  disableSaveBtn = false;
  ramConfigForm: FormGroup;
  selectedMachine: IMachineDTO;
  ramConfigurationsGridData: any[] = [];

  private editedRowIndex: number;
  private ramConfigs: IPressbedRamConfigurationDTO[] = [];

  @ViewChild('ramConfigurationEditorGrid')
  private ramConfigurationEditorGrid: GridComponent;

  constructor(@Inject(DialogRef)
    public data: IMachineDTO,
    private ramConfigurationService: RamConfigurationService,
    private notificationService: AppNotificationService,
    private translate: TranslateService,
    public ref: DialogRef) {
      super(ref);
     }

  ngOnInit(): void {
    this.ramConfigs = [];
    this.selectedMachine = this.data;
    this.ramConfigs = this.ramConfigurationsGridData = [...this.data.pressbedRamConfigurations];
  }

  editHandler({ sender, rowIndex, dataItem }): void {
    this.disableSaveBtn = true;
    this.closeEditor(sender);
    dataItem.state = ObjectState.Update;
    this.ramConfigForm = createRamConfigurationForm(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.ramConfigForm);
  }

  cancelHandler({ sender, rowIndex, dataItem }): void {
    this.disableSaveBtn = false;
    this.closeEditor(sender, rowIndex);
    dataItem.details.state = undefined;
  }

  saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const ramConfig = formGroup.value;

    if (ramConfig.position < 0 || ramConfig.width <= 0) {
      this.notificationService.notifyErrorAppChanel(this.translate.instant('Machine.RamConfigValidationErrorMsg'));
      return;
    }

    if (isNew) {
      const data = this.createObject(<IPressbedRamConfigurationDTO>{}, ramConfig);
      data.state = ObjectState.Add;
      this.setFormData(data);
      this.ramConfigs.push(data);
    } else {
      const existingRamConfig = this.getRamConfiguration(ramConfig.index);
      existingRamConfig.state = ObjectState.Update;
      const data = this.createObject(existingRamConfig, ramConfig);
      this.setFormData(data);
    }
    sender.closeRow(rowIndex);
    this.disableSaveBtn = false;
    this.ramConfigurationsGridData = this.ramConfigs.filter(x => x.state !== ObjectState.Delete);
  }

  close(): void {
    this.ref.close(false);
  }

  add(): void {
    this.closeEditor(this.ramConfigurationEditorGrid);
    const addRamConfig = <IPressbedRamConfigurationDTO>{
      index: this.ramConfigs.length ? this.ramConfigs.filter(x => x.state !== ObjectState.Delete)?.length + 1 : 1,
      position: 0,
      pressbedRamConfigurationId: 0,
      width: 0,
      machineId: this.selectedMachine.machineId,
      state: ObjectState.Add
    };
    this.ramConfigForm = createRamConfigurationForm(addRamConfig);
    this.ramConfigurationEditorGrid.addRow(this.ramConfigForm);
    this.disableSaveBtn = true;
  }

  save(): void {
    this.ramConfigurationService.save(this.ramConfigs).subscribe((result) => {
      this.notificationService.notifySucsessAppChanel(this.translate.instant('Machine.RamConfigurationSuccessMsg'));
      this.ref.close(true);
    }, error => {
      this.notificationService.notifyErrorAppChanel(error);
    });
  }

  removeHandler({ dataItem }): void {
    dataItem.state = ObjectState.Delete;
    const existingRamConfig = this.getRamConfiguration(dataItem.index);
    if (existingRamConfig) {
      existingRamConfig.state = ObjectState.Delete;
    }
    this.ramConfigurationsGridData = this.ramConfigs.filter(x => x.state !== ObjectState.Delete);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.ramConfigForm = undefined;
  }

  private setFormData(data: IPressbedRamConfigurationDTO) {
    this.ramConfigForm.get('ramConfigurationId').setValue(data.pressbedRamConfigurationId);
    this.ramConfigForm.get('machineId').setValue(data.machineId);
    this.ramConfigForm.get('position').setValue(data.position);
    this.ramConfigForm.get('width').setValue(data.width);
    this.ramConfigForm.get('index').setValue(data.index);
  }

  private getRamConfiguration(index: number): IPressbedRamConfigurationDTO {
    return this.ramConfigs.find(x => x.index === index);
  }

  private createObject(ramConfig: IPressbedRamConfigurationDTO, inputData: any): IPressbedRamConfigurationDTO {
    ramConfig.index = inputData.index;
    ramConfig.machineId = inputData.machineId;
    ramConfig.position = inputData.position;
    ramConfig.pressbedRamConfigurationId = inputData.ramConfigurationId;
    ramConfig.width = inputData.width;
    ramConfig.state = inputData.state;

    return ramConfig;
  }
}
