import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { getDemmandTypeValues } from '@app/core/models/aot-demand-type.enum';
import { Demandline } from '@app/core/models/demand-line.model';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-demand-info',
  templateUrl: './demand-info.component.html',
  styleUrls: ['./demand-info.component.css']
})
export class DemandInfoComponent extends DialogContentBase implements OnInit, OnDestroy {
  demandLine: Demandline = <Demandline> { };
  demandTypes = getDemmandTypeValues();

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(DialogRef) public data: { guid: string },
    private readonly beamOrderRowService: DeamndService,
    private readonly  dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

   ngOnInit() {
    if (this.data.guid) {
      this.subscriptions.push(
        this.beamOrderRowService.get(this.data.guid).subscribe((response) => {
          if (response) {
            this.demandLine = response;
          }
        })
      );
    }
  }

  getDeamndTypeText(id: number):string {
    return this.demandTypes.find(d => d.value === id)?.text;
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }

}
