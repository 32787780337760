import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { RamConfigurationDialogComponent } from '@app/modules/operative/ram-configuration-dialog/ram-configuration-dialog.component';
import { BaseService } from '../../custom-services/base.service';
import { AppNotificationService } from '../../custom-services/notification.service';
import { ConfigurationService } from '../../custom-services/configuration.service';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Injectable({
  providedIn: 'root'
})

export class RamConfigurationService extends BaseService {
  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService,
    private dialogService: DialogService
  ) {
    super(http, conf, notificationService);
  }

  get(machineId : number): Observable<Array<IPressbedRamConfigurationDTO>> {
    return this.http.get<IPressbedRamConfigurationDTO[]>(`${environment.apiUrl}RamConfiguration/${machineId}`);
  }

  save(ramConfigs: IPressbedRamConfigurationDTO[]): any {
    return this.http.post<any>(`${environment.apiUrl}RamConfiguration`, ramConfigs);
  }

  openDialog(machine: IMachineDTO): DialogRef {   
    const dialogRef: DialogRef = this.dialogService.open({
      content: RamConfigurationDialogComponent,
      maxWidth: '1000px',
      height: 'auto'
    });

    const content = dialogRef.content.instance as RamConfigurationDialogComponent;
    content.data = machine;

    return dialogRef;
  }
}
