import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Guid } from '@app/core/models/Guid';
import { GlulamSequenceResponseDTO, IGlulamSequenceResult } from '@app/core/models/glulamsequenceDTO';
import { ProductActivityDetails } from '@app/core/models/prod-order';
import { EntityService, EntityRelationDirection, IEntity } from '@app/core/services/http-services/model/entity.service';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';

marker('Operative.DemandActivitySelectionProgressCount');

@Component({
  selector: 'app-create-multi-step-glulam-sequence',
  templateUrl: './create-multi-step-glulam-sequence.component.html',
  styleUrls: ['./create-multi-step-glulam-sequence.component.css']
})
export class CreateMultiStepGlulamSequenceComponent extends DialogContentBase implements OnInit, OnDestroy {
  disableGenerateBtn = false;
  loading = false;
  progressingMsg = '';
  selectionLine: SelectionLine[] = [];
  allowedEntityGrp = ['FINISH_PLANE', 'MAKE_GLULAM', 'PLANE_LAMELLA', 'FINGERJOIN'];

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(DialogRef) public data: any,
    private readonly dialogRef: DialogRef,
    private readonly prodOrderService: ProdOrderService,
    private readonly entityService: EntityService,
    private readonly translateService: TranslateService) {
      super(dialogRef);
    }

  ngOnInit(): void {

    this.subscriptions.push(
      this.translateService.get('Operative.DemandActivitySelectionProgressCount', { currentIndex: this.data.current, totalCount: this.data.totalCount })
        .subscribe(x => {
          this.progressingMsg = x;
      })
    );

    const materialIndexes: Set<number> = new Set<number>();
    materialIndexes.add(this.data.materialIndex);

    this.subscriptions.push(
      this.prodOrderService.getActivityForMaterials(materialIndexes, EntityRelationDirection.PRODUCE)
        .subscribe(
          res => {
            const newGuid = Guid.newGuid();

            const next = <SelectionLine>{
              uniqueid: newGuid,
              productIndex: this.data.materialIndex,
              productDesc: res[0].productDesc,
              level: 1,
              activitys: res[0]?.activitys,
              selectedActvity: null
            };

            if (res[0].activitys.length === 0) {
              this.disableGenerateBtn = true;
            } else if (res[0].activitys.length === 1) {
              const a = res[0].activitys[0].index;
              this.getActivities(a, next);
            }

            this.selectionLine.push(next);
        })
    );
  }

  getDefaultItem() {
    return <ProductActivityDetails>{ productIndex: null, selectedActvity: null, description: 'select', activitys: [], level: -1, productDesc: '' };
  }

  save() {
    const lines = this.selectionLine.filter(x => x.productIndex).map(v => <GlulamSequenceResponseDTO>{
      level: v.level,
      activityIndex: v.selectedActvity,
      productIndex: v.productIndex,
      uniqueid: v.uniqueid,
      dependsOn: v.dependsOn,
      activityGroupCode: v.selectedActvityGroup
    });

    this.loading = false;

    this.dialogRef.close(<IGlulamSequenceResult> { success: true, lines: lines });
  }

  close(success: boolean) {
    this.dialogRef.close(<IGlulamSequenceResult> { success: success, lines: [] });
  }

  activityChange(newActivityIndex: number, item: SelectionLine) {
    this.removeChildRec(item.uniqueid);
    this.getActivities(newActivityIndex, item);
    this.validateCanCrare();
  }

  noSelection(item: SelectionLine): boolean {
    if (item.activitys.length > 0) {
      return !this.allowedEntityGrp.includes(item.activitys[0]?.entityGroupCode);
    }
  }

  async getActivities(newActivityIndex: number, item: SelectionLine) {
    const act = await this.entityService.get(newActivityIndex).toPromise();

    item.selectedActvityGroup = act.entityGroupCode;
    item.selectedActvity = newActivityIndex;

    const consMaterials = act.entityRelations.filter(a =>
      a.direction === EntityRelationDirection.CONSUMES &&
      a.unitCode !== 'HOUR' &&
      a.product.entityGroupCode !== 'STRESS_GRADED');

    for (const m of consMaterials) {
      const newGuid = Guid.newGuid();
      const materialIndexes: Set<number> = new Set<number>();
      materialIndexes.add(m.productIndex);

      const res = await this.prodOrderService.getActivityForMaterials(materialIndexes, EntityRelationDirection.PRODUCE).toPromise();
      const newx = <SelectionLine>{ productIndex: m.productIndex, productDesc: m.product.description, level: item.level + 1, uniqueid: newGuid, dependsOn: item.uniqueid, activitys: res[0].activitys, selectedActvity: null };

      if (res[0].activitys.length === 1 && !this.noSelection(newx)) {
        const a = res[0].activitys[0].index;
        newx.selectedActvity = a;
        this.getActivities(a, newx);
      }

      this.selectionLine.push(newx);
    }

    this.selectionLine.sort((a, b) => a.level - b.level);
  }

  removeChildRec(guid: string): void {
    const dep = this.selectionLine.filter(i => i.dependsOn === guid);

    for (const d of dep) {
      this.removeChildRec(d.uniqueid);

      const index = this.selectionLine.indexOf(d, 0);
      if (index > -1) {
        this.selectionLine.splice(index, 1);
      }
    }
  }

  validateCanCrare() {
    if (this.selectionLine.find(d => d.selectedActvity == null)) {
      this.disableGenerateBtn = true;
    } else {
      this.disableGenerateBtn = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }

}

export class SelectionLine {
  productIndex: number;
  productDesc: string;
  activitys: IEntity[];
  selectedActvity: number;
  selectedActvityGroup: string;
  level: number;
  dependsOn?: string;
  uniqueid?: string;
}
