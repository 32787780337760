<kendo-dialog [maxHeight]="'auto'" [maxWidth]="'1000px'">
  <kendo-dialog-titlebar *ngIf="isNew" kendo-dialog-title>
    <div translate>Operative.NewInstruction</div>
  </kendo-dialog-titlebar>
  <kendo-dialog-titlebar *ngIf="!isNew" kendo-dialog-title>
    <div translate>Operative.EditInstruction</div>
  </kendo-dialog-titlebar>
<form [formGroup]="instructionForm" (ngSubmit)="onSubmit()">
  <div style="margin-top: 10px;"></div>
  <div class="popupContent">
    <section>
      <div class="row flex-grow-1">
        <kendo-formfield>
          <kendo-floatinglabel text="{{ 'App.Name'  | translate }}">
            <kendo-textbox formControlName="name" [style.width.px]="350">
            </kendo-textbox>
          </kendo-floatinglabel>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-floatinglabel text="{{ 'App.Code'  | translate }}">
            <kendo-textbox formControlName="code" [style.width.px]="350">
            </kendo-textbox>
          </kendo-floatinglabel>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-floatinglabel text="{{ 'Operative.MaterialPropertyCode'  | translate }}">
            <kendo-combobox  [data]="property"
                             formControlName="materialPropertyCode"
                             textField="description"
                             valueField="code"
                             placeholder="{{'EntityTranslation.SearchProperty' | translate}}"
                             [valuePrimitive]="true">
            </kendo-combobox>
          </kendo-floatinglabel>
        </kendo-formfield>
      </div>
      <br />
      <div class="row" style="gap: 100px;">
        <div>
          <h3 translate>Operative.ValidatedPlannerInstructions</h3>
        </div>
        <div>
          <a mat-icon-button class="margin-bottom-validatedValues"
                             color="primary"
                             (click)="addValidatedValue()"
                             title="{{ 'EntityTranslation.AddValidatedValue' | translate }}">
            <mat-icon>add_circle</mat-icon>
          </a>
        </div>
      </div>
      <div formArrayName="instructionConfigurations"
           class="column"
           style="gap: 5px;">
        <ng-container *ngFor="let config of instructionConfigurations.controls let i=index"
                      [formGroupName]="i"
                      class="row">
          <span class="row" *ngIf="config.get('isRemoved').value != true">
            <kendo-textbox formControlName="configurationValue"
                          style="width: 350px;">
            </kendo-textbox>
            <a mat-icon-button color="warn"
                               (click)="removeValidValue(i)"
                               title="{{'EntityTranslation.Remove' | translate}}">
              <mat-icon>remove_circle</mat-icon>
            </a>
          </span>
          <br />
        </ng-container>
      </div>
    </section>
  </div>
  <kendo-dialog-actions class="row">
    <button
      kendoButton
      (click)="cancel()"
      [themeColor]="'error'">
      {{ 'EntityTranslation.Cancle' | translate }}
    </button>
    <button
      kendoButton
      type="submit"
      [disabled]="!instructionForm?.valid"
      [primary]="true">
      {{  (isNew ? 'App.Add' : 'App.Update') | translate }}
    </button>
  </kendo-dialog-actions>
</form>
</kendo-dialog>
