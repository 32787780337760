import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { IInstructionConfigurationFormGroup, IInstructionForm } from '@app/core/models/forms/operative/Instruction/instruction-form.model';
import { IInstructionConfiguration } from '@app/core/models/instruction-configuration.model';
import { IInstruction } from '@app/core/models/instruction.model';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { EntityPropertyService, IEntityProperty } from '@app/core/services/http-services/model/entity-property.service';
import { InstructionService } from '@app/core/services/http-services/operative/instruction.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

marker('Operative.SaveInstructionMsg');

const createInstructionForm = (i: IInstruction, isNew: boolean) => new FormGroup<IInstructionForm>({
  name: new FormControl(i.name, Validators.required),
  code:new FormControl(i.code, Validators.required),
  materialPropertyCode:new FormControl(i.materialPropertyCode),
  isNew: new UntypedFormControl(isNew),
  instructionID: new UntypedFormControl(i.instructionID),
  instructionConfigurations: new UntypedFormArray(i.instructionConfigurations.map<UntypedFormGroup>(k => createInstructionConfigurationFormGroup(k, false)))
});

export const createInstructionConfigurationFormGroup = (ic: IInstructionConfiguration, isNew: boolean) => new FormGroup<IInstructionConfigurationFormGroup>({
  instructionID: new FormControl(ic.instructionID),
  instructionConfigurationId: new FormControl(ic.instructionConfigurationId),
  configurationValue: new FormControl(ic.configurationValue, Validators.required),
  isNew: new FormControl(isNew),
  isChange: new FormControl(false),
  isRemoved: new FormControl(false)
});

@Component({
  selector: 'app-add-edit-instruction-dialog',
  templateUrl: './add-edit-instruction-dialog.component.html',
  styleUrls: ['./add-edit-instruction-dialog.component.css']
})

export class AddEditInstructionDialogComponent extends DialogContentBase implements OnInit {
  isNew: boolean;
  instructionForm: FormGroup;
  selectedInstruction: IInstruction;
  property:IEntityProperty[]=[];

  constructor(@Inject(DialogRef)
    public data: { instruction: IInstruction, isNew: boolean },
    public dialogRef: DialogRef,
    private instructionService: InstructionService,
    private notificationService: AppNotificationService,
    private translateService: TranslateService,
    private entityPropertyService:EntityPropertyService) { super(dialogRef); }

  ngOnInit(): void {
    this.selectedInstruction = this.data.instruction;
    this.isNew = this.data.isNew;
    this.getProperty();
    this.instructionForm = createInstructionForm(this.selectedInstruction, this.isNew);

    this.instructionForm.get('name').valueChanges.subscribe((value) => {
      if (value.trim() === '') {
        this.instructionForm.get('name').setErrors({ incorrect: true });
      }
    });
  }

  get instructionConfigurations(): FormArray {
    return this.instructionForm.get('instructionConfigurations') as FormArray;
  }

  addValidatedValue(): void {
    this.instructionConfigurations.push(
      createInstructionConfigurationFormGroup(<IInstructionConfiguration>
        { configurationValue: '' },
      true));
  }

  removeValidValue(index): void {
    this.instructionConfigurations.at(index).get('isRemoved').patchValue(true);
    if (this.instructionConfigurations.at(index).get('isNew').value) {
      this.instructionConfigurations.removeAt(index);
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    const formValue = this.instructionForm.value;

    this.selectedInstruction.name = formValue.name;
    this.selectedInstruction.instructionID = formValue.instructionID;
    this.selectedInstruction.code = formValue.code;
    this.selectedInstruction.materialPropertyCode = formValue.materialPropertyCode
    this.selectedInstruction.instructionConfigurations = formValue.instructionConfigurations.map(ic => {
      return <IInstructionConfiguration>{
        instructionConfigurationId: ic.instructionConfigurationId ?? 0,
        instructionID: this.selectedInstruction.instructionID,
        configurationValue: ic.configurationValue,
        isNew: ic.isNew,
        isChange: ic.isChange,
        isRemoved: ic.isRemoved
      };
    });
    this.save();
  }

  private save(): void {
    this.instructionService.insert(this.selectedInstruction).subscribe((result) => {
      this.notificationService.notifySucsessAppChanel(this.translateService.instant('Operative.SaveInstructionMsg'));
      this.dialogRef.close(true);
    }, error => {
      this.notificationService.notifyErrorAppChanel(error);
    });
  }

  getProperty(){
    this.entityPropertyService.getEntityProperties().subscribe((d) => {
      this.property = d;
    });
  }
}
