import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Demandline } from '@app/core/models/demand-line.model';
import { IDemandLamellaSpecificationDTO } from '@app/core/models/demand-lamella-specification.model';
import { IDemandLamellaSpecificationDetails } from '@app/core/models/demand-lamella-specification-details.model';
import { ObjectState } from '@app/core/models/object-state.enum';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DemandSpecificationService } from '@app/core/services/http-services/aot/demand-specification.service';
import { IEntity, EntityService } from '@app/core/services/http-services/model/entity.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';

marker('AoT.DemandSpecificationSuccessMsg');
marker('AoT.DemandSpecificationDoesNotExists');

const createDemandSpecificationForm = (p: IDemandLamellaSpecificationDetails) => {
  return new UntypedFormGroup({
    numberOfLamellas: new UntypedFormControl(p.details.numberOfLamellas, [Validators.required]),
    edgeOffset: new UntypedFormControl(p.details.edgeOffset, [Validators.required]),
    lengthOfLamellas: new UntypedFormControl(p.details.lengthOfLamellas, [Validators.required]),
    materialIndex: new UntypedFormControl(p.details.materialIndex),
    materialDescription: new UntypedFormControl(p.details.materialDescription, [Validators.required]),
    noGlue: new UntypedFormControl(p.details.noGlue, [Validators.required]),
    lamellaPlaningThickness: new UntypedFormControl(p.details.lamellaPlaningThickness, [Validators.required]),
    turn: new UntypedFormControl(p.details.turn, [Validators.required]),
    demandLamellaSpecificationId: new UntypedFormControl(p.details.demandLamellaSpecificationId),
    state: new UntypedFormControl(p.details.state),
    lamellaPosition: new UntypedFormControl(p.lamellaPosition)
  });
};

@Component({
  selector: 'app-demand-specification-dialog',
  templateUrl: './demand-specification-dialog.component.html',
  styleUrls: ['./demand-specification-dialog.component.css']
})

export class DemandSpecificationDialogComponent extends DialogContentBase implements OnInit {
  isNew = false;
  loading = false;
  formGroup: UntypedFormGroup;
  disableSaveBtn = false;
  demandSpecForm: UntypedFormGroup;
  altModels: IEntity[] = [];
  selectedDemand: Demandline;
  status = [
    { text: 'Yes', value: true },
    { text: 'No', value: false }
  ];

  demandSpecificationsGridData: any[] = [];
  public entityTypeCode: string = 'RESOURCE';

  private editedRowIndex: number;
  private demandSpecs: IDemandLamellaSpecificationDetails[] = [];

  @ViewChild('demandSpecificationEditorGrid')
  private demandSpecificationEditorGrid: GridComponent;


  constructor(@Inject(DialogRef) public data: Demandline,
    public dialogRef: DialogRef,
    public entityService: EntityService,
    private notificationService: AppNotificationService,
    private translate: TranslateService,
    private demandSpecificationService: DemandSpecificationService
  ) { super(dialogRef);  }

  ngOnInit(): void {
    this.selectedDemand = this.data;
    this.getDemandSpecifications();
  }

  setSelectedMaterial(material: IEntity) {
    if (material !== undefined) {
      this.demandSpecForm.patchValue({
        materialDescription: material.description
      });
    }
  }

  closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.demandSpecForm = undefined;
  }

  createObject(demandSpec: IDemandLamellaSpecificationDetails, inputData: any): IDemandLamellaSpecificationDetails {
    demandSpec.lamellaPosition = inputData.lamellaPosition;
    demandSpec.details = {
      beamOrderRowId: this.selectedDemand.BeamOrderRowID,
      edgeOffset: inputData.edgeOffset,
      lengthOfLamellas: inputData.lengthOfLamellas,
      materialIndex: inputData.materialIndex,
      materialDescription: inputData.materialDescription,
      noGlue: inputData.noGlue,
      lamellaPlaningThickness: inputData.lamellaPlaningThickness,
      turn: inputData.turn,
      numberOfLamellas: inputData.numberOfLamellas,
      demandLamellaSpecificationId: inputData.demandLamellaSpecificationId,
      state: inputData.state
    };
    return demandSpec;
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.disableSaveBtn = true;
    this.closeEditor(sender);
    dataItem.details.state = ObjectState.Update;
    this.demandSpecForm = createDemandSpecificationForm(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.demandSpecForm);
  }

  cancelHandler({ sender, rowIndex, dataItem }) {
    this.disableSaveBtn = false;
    this.closeEditor(sender, rowIndex);
    dataItem.details.state = undefined;
  }

  saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const demandSpecification = formGroup.value;

    if (isNew) {
      const data = this.createObject(<IDemandLamellaSpecificationDetails>{}, demandSpecification);
      data.details.state = ObjectState.Add;
      this.setFormData(data);
      this.demandSpecs.push(data);
    } else {
      const existingDemandSpec = this.getDemandSpec(demandSpecification.lamellaPosition);
      existingDemandSpec.details.state = ObjectState.Update;
      const data = this.createObject(existingDemandSpec, demandSpecification);
      this.setFormData(data);
    }
    sender.closeRow(rowIndex);
    this.disableSaveBtn = false;
  };

  removeHandler({ dataItem }): void {
    dataItem.state = ObjectState.Delete;

    const existingDemandSpecification = this.getDemandSpec(dataItem.lamellaPosition);
    if (existingDemandSpecification) {
      existingDemandSpecification.details.state = ObjectState.Delete;
    }
    this.demandSpecificationsGridData = this.demandSpecs.filter(x => x.details.state !== ObjectState.Delete);
  }

  add(): void {
    this.closeEditor(this.demandSpecificationEditorGrid);
    const addDemandSpec = <IDemandLamellaSpecificationDetails>{
      lamellaPosition: this.demandSpecs.length ? this.demandSpecs.length + 1 : 1,
      details: {
        beamOrderRowId: this.selectedDemand.BeamOrderRowID,
        demandLamellaSpecificationId: 0,
        edgeOffset: 0,
        lengthOfLamellas: 1,
        materialIndex: 0,
        materialDescription: '',
        numberOfLamellas: 1,
        noGlue: false,
        lamellaPlaningThickness: 1,
        turn: false,
        state: ObjectState.Add
      }
    };
    this.demandSpecForm = createDemandSpecificationForm(addDemandSpec);
    this.demandSpecificationEditorGrid.addRow(this.demandSpecForm);
    this.disableSaveBtn = true;
  }

  save(): void {
    this.demandSpecificationService.save(this.createDTOForAPI(this.demandSpecs)).subscribe((demand) => {
      this.notificationService.notifySucsessAppChanel(this.translate.instant('AoT.DemandSpecificationSuccessMsg'));
      this.dialogRef.close(demand);
    }, error => {
      this.notificationService.notifyErrorAppChanel(error);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  private getDemandSpec(lamellaPosition: number): IDemandLamellaSpecificationDetails {
    return this.demandSpecs.find(x => x.lamellaPosition === lamellaPosition);
  }

  private getDemandSpecifications(): void {
    this.demandSpecificationService.get(this.selectedDemand.BeamOrderRowID).subscribe(result => {
      if (result) {
        this.demandSpecs = this.convertDTOToDetails(result);
        this.demandSpecificationsGridData = this.demandSpecs;
      }
    }, error => {
      this.notificationService.notifyErrorAppChanel(error);
    });
  }

  private setFormData(data: IDemandLamellaSpecificationDetails) {
    this.demandSpecForm.get('lamellaPosition').setValue(data.lamellaPosition);
    this.demandSpecForm.get('edgeOffset').setValue(data.details.edgeOffset);
    this.demandSpecForm.get('lengthOfLamellas').setValue(data.details.lengthOfLamellas);
    this.demandSpecForm.get('materialIndex').setValue(data.details.materialIndex);
    this.demandSpecForm.get('materialDescription').setValue(data.details.materialDescription);
    this.demandSpecForm.get('noGlue').setValue(data.details.noGlue);
    this.demandSpecForm.get('lamellaPlaningThickness').setValue(data.details.lamellaPlaningThickness);
    this.demandSpecForm.get('turn').setValue(data.details.turn);
    this.demandSpecForm.get('numberOfLamellas').setValue(data.details.numberOfLamellas);
  }

  private convertDTOToDetails(data: IDemandLamellaSpecificationDTO[]): IDemandLamellaSpecificationDetails[] {
    const convertedData: IDemandLamellaSpecificationDetails[] = [];

    data.forEach((d, index) => {
      const detail: IDemandLamellaSpecificationDetails = {
        lamellaPosition: index + 1,
        details: this.convertToDTOObject(d)
      };
      convertedData.push(detail);
    });

    return convertedData;
  }

  private createDTOForAPI(data: IDemandLamellaSpecificationDetails[]): IDemandLamellaSpecificationDTO[] {
    const dtoDetails: IDemandLamellaSpecificationDTO[] = [];

    data.forEach(d => {
      dtoDetails.push(d.details);
    });

    return dtoDetails;
  }

  private convertToDTOObject(data: IDemandLamellaSpecificationDTO) {
    const newData: IDemandLamellaSpecificationDTO = {
      beamOrderRowId: this.selectedDemand.BeamOrderRowID,
      demandLamellaSpecificationId: data.demandLamellaSpecificationId > 0 ? data.demandLamellaSpecificationId : 0,
      edgeOffset: data.edgeOffset,
      lengthOfLamellas: data.lengthOfLamellas,
      materialIndex: data.materialIndex,
      materialDescription: data.materialDescription,
      noGlue: data.noGlue,
      lamellaPlaningThickness: data.lamellaPlaningThickness,
      turn: data.turn,
      numberOfLamellas: data.numberOfLamellas,
      state: undefined
    };
    return newData;
  }
}
